import { useTranslation } from "react-i18next";
import useModalsStore from "../../store/modalsStore";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Logo from "../../assets/logo";
import LoadingScreen from "../loading-screen";
import { getAddresses } from "../../actions/getAddresses";

export default function Addresses() {
    const { t } = useTranslation();
    const setMapModalOpen = useModalsStore((state) => state.setMapModalOpen);
    const navigate = useNavigate();
    const { data, isFetching, refetch } = useQuery({
        queryKey: ["global", "addresses"],
        queryFn: getAddresses,
    });
    const setModalContent = useModalsStore((state) => state.setModalContent);
    const setModalOpen = useModalsStore((state) => state.setModalOpen);
    const modalContent = useModalsStore((state) => state.modalContent);

    if (isFetching) return <LoadingScreen />;
    if (!data) return null;

    async function deleteAddress(id: string) {
        setModalContent({
            ...modalContent,
            loading: true,
        });
        try {
            const formData = new FormData();
            formData.append("address_id", id.toString());
            await axios.post("/user/address/delete", formData);
            await refetch();
        } catch (error) {
            console.error(error);
        }
        setModalContent({
            ...modalContent,
            loading: false,
        });
        setModalOpen(false);
    }

    return (
        <div className="container mx-auto flex max-w-[852px] flex-col px-4 pt-14">
            <h2 className="mb-10 text-center text-[40px] font-medium text-[rgba(23,23,23,1)]">
                {t("saved-addresses")}
            </h2>
            <div className="flex flex-col gap-4">
                {data.map((address) => (
                    <div
                        key={address.id}
                        className="flex flex-col rounded-lg border border-[rgba(229,229,229,1)] p-4"
                    >
                        <div className="flex w-full items-center justify-between gap-2">
                            <svg
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.0001 14.5491C14.8668 14.5491 16.3801 13.0358 16.3801 11.1691C16.3801 9.30234 14.8668 7.78906 13.0001 7.78906C11.1334 7.78906 9.62012 9.30234 9.62012 11.1691C9.62012 13.0358 11.1334 14.5491 13.0001 14.5491Z"
                                    stroke="#292D32"
                                    strokeWidth="1.625"
                                />
                                <path
                                    d="M3.92168 9.19685C6.05584 -0.184815 19.955 -0.173982 22.0783 9.20769C23.3242 14.711 19.9008 19.3694 16.9 22.251C14.7225 24.3527 11.2775 24.3527 9.08918 22.251C6.09918 19.3694 2.67584 14.7002 3.92168 9.19685Z"
                                    stroke="#292D32"
                                    strokeWidth="1.625"
                                />
                            </svg>
                            <span className="mb-0.5 text-xl font-medium text-[rgba(23,23,23,1)]">
                                {t("deliver-to")}
                            </span>
                            <div className="ms-auto flex gap-2">
                                {/* <button>
                                    <svg
                                        width="44"
                                        height="43"
                                        viewBox="0 0 44 43"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g filter="url(#filter0_d_258_198587)">
                                            <rect
                                                x="6"
                                                y="3"
                                                width="32"
                                                height="32"
                                                rx="16"
                                                fill="white"
                                            />
                                            <path
                                                d="M22.985 12.4679L16.5995 19.2268C16.3584 19.4835 16.125 19.989 16.0784 20.339L15.7906 22.859C15.6895 23.769 16.3428 24.3913 17.245 24.2357L19.7495 23.8079C20.0995 23.7457 20.5895 23.489 20.8306 23.2246L27.2162 16.4657C28.3206 15.299 28.8184 13.969 27.0995 12.3435C25.3884 10.7335 24.0895 11.3013 22.985 12.4679Z"
                                                stroke="#404040"
                                                strokeWidth="1.33333"
                                                strokeMiterlimit="10"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M21.9189 13.5957C22.2534 15.7424 23.9956 17.3835 26.1578 17.6013"
                                                stroke="#404040"
                                                strokeWidth="1.33333"
                                                strokeMiterlimit="10"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M15.0049 26.7793H29.0049"
                                                stroke="#404040"
                                                strokeWidth="1.33333"
                                                strokeMiterlimit="10"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </g>
                                        <defs>
                                            <filter
                                                id="filter0_d_258_198587"
                                                x="0.666667"
                                                y="0.333333"
                                                width="42.6667"
                                                height="42.6667"
                                                filterUnits="userSpaceOnUse"
                                                color-interpolation-filters="sRGB"
                                            >
                                                <feFlood
                                                    flood-opacity="0"
                                                    result="BackgroundImageFix"
                                                />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dy="2.66667" />
                                                <feGaussianBlur stdDeviation="2.66667" />
                                                <feComposite
                                                    in2="hardAlpha"
                                                    operator="out"
                                                />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0.1 0 0 0 0 0.1 0 0 0 0 0.1 0 0 0 0.16 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_258_198587"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_258_198587"
                                                    result="shape"
                                                />
                                            </filter>
                                        </defs>
                                    </svg>
                                </button> */}
                                <button
                                    onClick={() => {
                                        setModalContent({
                                            title: (
                                                <>
                                                    {t("do-you-want")}{" "}
                                                    <span className="font-semibold">
                                                        {t("delete-address")}
                                                    </span>{" "}
                                                    {t("?")}
                                                </>
                                            ),
                                            confirmText: t("yes"),
                                            cancelText: t("no"),
                                            onConfirm: () =>
                                                deleteAddress(address.id),
                                            onCancel: () => {
                                                setModalOpen(false);
                                            },
                                            loading: false,
                                            icon: (
                                                <Logo className="size-[63px]" />
                                            ),
                                        });
                                        setModalOpen(true);
                                    }}
                                >
                                    <svg
                                        width="44"
                                        height="43"
                                        viewBox="0 0 44 43"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g filter="url(#filter0_d_258_198589)">
                                            <rect
                                                x="6"
                                                y="3"
                                                width="32"
                                                height="32"
                                                rx="16"
                                                fill="white"
                                            />
                                            <path
                                                d="M29.0049 14.3147C26.4149 14.058 23.8093 13.9258 21.2115 13.9258C19.6715 13.9258 18.1315 14.0036 16.5915 14.1591L15.0049 14.3147"
                                                stroke="#FF4B4B"
                                                strokeWidth="1.33333"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M19.2832 13.5297L19.4543 12.5108C19.5788 11.7719 19.6721 11.2197 20.9865 11.2197H23.0243C24.3388 11.2197 24.4399 11.8031 24.5565 12.5186L24.7276 13.5297"
                                                stroke="#FF4B4B"
                                                strokeWidth="1.33333"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M27.3323 16.7725L26.8268 24.6047C26.7412 25.8258 26.6712 26.7747 24.5012 26.7747H19.5079C17.3379 26.7747 17.2679 25.8258 17.1823 24.6047L16.6768 16.7725"
                                                stroke="#FF4B4B"
                                                strokeWidth="1.33333"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M20.707 22.4971H23.297"
                                                stroke="#FF4B4B"
                                                strokeWidth="1.33333"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M20.0605 19.3867H23.9494"
                                                stroke="#FF4B4B"
                                                strokeWidth="1.33333"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </g>
                                        <defs>
                                            <filter
                                                id="filter0_d_258_198589"
                                                x="0.666667"
                                                y="0.333333"
                                                width="42.6667"
                                                height="42.6667"
                                                filterUnits="userSpaceOnUse"
                                                color-interpolation-filters="sRGB"
                                            >
                                                <feFlood
                                                    flood-opacity="0"
                                                    result="BackgroundImageFix"
                                                />
                                                <feColorMatrix
                                                    in="SourceAlpha"
                                                    type="matrix"
                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                    result="hardAlpha"
                                                />
                                                <feOffset dy="2.66667" />
                                                <feGaussianBlur stdDeviation="2.66667" />
                                                <feComposite
                                                    in2="hardAlpha"
                                                    operator="out"
                                                />
                                                <feColorMatrix
                                                    type="matrix"
                                                    values="0 0 0 0 0.1 0 0 0 0 0.1 0 0 0 0 0.1 0 0 0 0.16 0"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in2="BackgroundImageFix"
                                                    result="effect1_dropShadow_258_198589"
                                                />
                                                <feBlend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="effect1_dropShadow_258_198589"
                                                    result="shape"
                                                />
                                            </filter>
                                        </defs>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <span className="ms-[36px] text-[rgba(82,82,82,1)]">
                            {address.emirate?.name} - {address.region?.name} -
                            {t("street-name")} {address.street_name} -{" "}
                            {t("building-number")} {address.building_number}
                        </span>
                    </div>
                ))}
            </div>
            <div className="mx-auto mt-20 flex w-full max-w-[368px] flex-col gap-4">
                <button
                    onClick={() => {
                        navigate(
                            "/account/add-new-address?redirectTo=/account/addresses",
                        );
                        setMapModalOpen(true);
                    }}
                    className="flex items-center justify-center rounded-lg border border-primary bg-primary px-4 py-[18px] text-white transition hover:opacity-80"
                >
                    {t("add-new-address")}
                </button>{" "}
            </div>
        </div>
    );
}
