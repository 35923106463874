export const Medal = () => (
    <svg
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="me-2"
    >
        <path
            d="M23.2235 20.4541C23.1068 20.4572 22.9949 20.5011 22.9071 20.5781C21.4429 21.8636 19.6505 22.7164 17.7294 23.041C17.655 23.0535 17.5845 23.0827 17.5231 23.1264C17.4616 23.17 17.4108 23.2269 17.3745 23.2929C17.3381 23.3589 17.3172 23.4323 17.3132 23.5076C17.3092 23.5828 17.3223 23.658 17.3514 23.7275L20.368 30.9189C20.4065 31.0102 20.4712 31.0881 20.554 31.1426C20.6368 31.197 20.7339 31.2257 20.833 31.2249C20.9321 31.2241 21.0287 31.1938 21.1105 31.138C21.1924 31.0822 21.2559 31.0033 21.2928 30.9114L22.6766 27.4631L26.1756 28.8674C26.2671 28.9042 26.3675 28.913 26.464 28.8929C26.5605 28.8727 26.6489 28.8245 26.7181 28.7543C26.7873 28.6841 26.8342 28.5949 26.8529 28.4981C26.8715 28.4013 26.8612 28.3011 26.8231 28.2102L23.6981 20.7599C23.6591 20.6669 23.593 20.5879 23.5083 20.5332C23.4236 20.4785 23.3243 20.4514 23.2235 20.4541Z"
            fill="#648C74"
        />
        <path
            d="M8.30387 20.7708L5.18082 28.2161C5.00943 28.6254 5.41645 29.0386 5.82829 28.8733L9.32731 27.469L10.7111 30.9173C10.878 31.3315 11.4629 31.3358 11.6359 30.9248L14.6564 23.7247C14.7811 23.4272 14.5957 23.0912 14.2775 23.0382C12.3557 22.7183 10.5611 21.8696 9.09391 20.5879C9.00499 20.5105 8.89154 20.4668 8.7736 20.4649C8.56904 20.4611 8.38297 20.582 8.30387 20.7708Z"
            fill="#648C74"
        />
        <path
            d="M16.001 1.19141C9.65563 1.19141 4.5 6.34704 4.5 12.6924C4.5 19.0377 9.65563 24.1897 16.001 24.1897C22.3463 24.1897 27.502 19.0377 27.502 12.6924C27.502 6.34704 22.3463 1.19141 16.001 1.19141Z"
            fill="#FFCB3C"
        />
        <path
            d="M15.9985 3.6875C11.0339 3.6875 7 7.72508 7 12.6897C7 17.6544 11.0339 21.6882 15.9985 21.6882C20.9632 21.6882 24.997 17.6544 24.997 12.6897C24.997 7.72508 20.9632 3.6875 15.9985 3.6875Z"
            fill="#FFEA54"
        />
        <path
            d="M16.0005 6.94666C15.9049 6.95375 15.8135 6.9881 15.7369 7.04568C15.6604 7.10326 15.6019 7.18164 15.5686 7.27146L14.2436 10.7816L10.4899 10.8959C10.388 10.8995 10.2896 10.9343 10.208 10.9955C10.1264 11.0566 10.0654 11.1413 10.0333 11.2381C10.0011 11.3349 9.99938 11.4392 10.0282 11.537C10.0571 11.6348 10.1151 11.7215 10.1946 11.7854L13.1215 14.1328L12.0733 17.7352C12.0444 17.8336 12.0465 17.9386 12.0792 18.0359C12.112 18.1331 12.1739 18.2179 12.2565 18.2787C12.3392 18.3396 12.4385 18.3735 12.5411 18.376C12.6437 18.3785 12.7445 18.3493 12.83 18.2925L15.9636 16.233L19.0677 18.3442C19.1523 18.402 19.2526 18.4324 19.3551 18.4313C19.4576 18.4302 19.5572 18.3977 19.6406 18.3381C19.7239 18.2785 19.787 18.1947 19.8212 18.0981C19.8553 18.0015 19.859 17.8967 19.8317 17.7979L18.8425 14.1771L21.8063 11.8813C21.8879 11.8191 21.9483 11.7332 21.9794 11.6354C22.0104 11.5376 22.0107 11.4327 21.98 11.3347C21.9494 11.2368 21.8894 11.1506 21.8081 11.088C21.7269 11.0253 21.6283 10.9892 21.5258 10.9844L17.7758 10.8072L16.5061 7.27867C16.4697 7.17566 16.4006 7.08739 16.3092 7.0274C16.2179 6.96741 16.1095 6.93918 16.0005 6.94666Z"
            fill="#FFCB3C"
        />
    </svg>
);

export const Favorite = () => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.1635 19.8251C16.9064 21.8018 14.5988 23.1093 14.1553 23.3521C13.7119 23.1093 11.4042 21.8018 9.14709 19.8251C6.68018 17.6647 4.5207 14.9193 4.52046 12.0489C4.52207 10.7417 5.04208 9.48847 5.96644 8.5641C6.89081 7.63973 8.14405 7.11972 9.45129 7.11811C11.1301 7.11825 12.555 7.83571 13.433 9.00501L14.1553 9.96704L14.8776 9.00501C15.7556 7.83571 17.1806 7.11825 18.8593 7.11811C20.1666 7.11972 21.4198 7.63973 22.3442 8.5641C23.2684 9.48835 23.7884 10.7414 23.7902 12.0484C23.7902 14.9189 21.6306 17.6645 19.1635 19.8251Z"
            stroke="#0A4C26"
            strokeWidth="1.80654"
        />
    </svg>
);

export const SavedAddresses = () => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M25.6673 10.6918V17.6918C25.6673 20.6085 25.084 22.6502 23.7773 23.9685L16.334 16.5252L25.3523 7.50684C25.5623 8.4285 25.6673 9.4785 25.6673 10.6918Z"
            stroke="#0A4C26"
            strokeWidth="1.80054"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M25.3523 7.50657L7.31564 25.5432C3.80397 24.7382 2.33398 22.3116 2.33398 17.6916V10.6916C2.33398 4.85824 4.66732 2.5249 10.5007 2.5249H17.5007C22.1207 2.5249 24.5473 3.99491 25.3523 7.50657Z"
            stroke="#0A4C26"
            strokeWidth="1.80054"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M23.7761 23.9678C22.4578 25.2744 20.4161 25.8577 17.4995 25.8577H10.4995C9.28613 25.8577 8.23612 25.7527 7.31445 25.5427L16.3328 16.5244L23.7761 23.9678Z"
            stroke="#0A4C26"
            strokeWidth="1.80054"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.27982 9.50125C8.07315 6.08292 13.2065 6.08292 13.9998 9.50125C14.4548 11.5079 13.1948 13.2113 12.0865 14.2613C11.2815 15.0313 10.0098 15.0313 9.19317 14.2613C8.08484 13.2113 6.81315 11.5079 7.27982 9.50125Z"
            stroke="#0A4C26"
            strokeWidth="1.80054"
        />
        <path
            d="M10.6101 10.3411H10.6206"
            stroke="#0A4C26"
            strokeWidth="2.40072"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const BankCard = () => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 34 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.56738 22.687L22.4966 5.75781"
            stroke="#0A4C26"
            strokeWidth="2.125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.7275 26.0867L17.4275 24.3867"
            stroke="#0A4C26"
            strokeWidth="2.125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M19.54 22.2755L22.9259 18.8896"
            stroke="#0A4C26"
            strokeWidth="2.125"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.10147 14.6967L14.5081 5.29003C17.5115 2.2867 19.0131 2.27253 21.9881 5.24753L28.944 12.2034C31.919 15.1784 31.9048 16.68 28.9015 19.6834L19.4948 29.09C16.4915 32.0934 14.9898 32.1075 12.0148 29.1325L5.05897 22.1767C2.08397 19.2017 2.08397 17.7142 5.10147 14.6967Z"
            stroke="#0A4C26"
            strokeWidth="2.125"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.83301 31.356H31.1663"
            stroke="#0A4C26"
            strokeWidth="2.125"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const DiscountCoupon = () => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M22.061 17.3325C22.061 18.8375 23.2977 20.0625 24.8027 20.0625C24.8027 24.4375 23.706 25.5342 19.331 25.5342H8.38768C4.01268 25.5342 2.91602 24.4375 2.91602 20.0625V19.5258C4.42102 19.5258 5.65768 18.2892 5.65768 16.7842C5.65768 15.2792 4.42102 14.0425 2.91602 14.0425V13.5058C2.92768 9.13085 4.01268 8.03418 8.38768 8.03418H19.3194C23.6944 8.03418 24.791 9.13085 24.791 13.5058V14.6025C23.286 14.6025 22.061 15.8158 22.061 17.3325Z"
            stroke="#0A4C26"
            strokeWidth="1.80054"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.9126 8.03449H8.30762L11.726 4.61615C14.5143 1.82782 15.9143 1.82782 18.7026 4.61615L19.4026 5.31615C18.6676 6.05115 18.4926 7.13615 18.9126 8.03449Z"
            stroke="#0A4C26"
            strokeWidth="1.80054"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.5254 8.03467L11.5254 25.5347"
            stroke="#0A4C26"
            strokeWidth="1.80054"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="6 6"
        />
    </svg>
);

export const Chevron = () => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="ms-auto rtl:rotate-180"
    >
        <path
            d="M11.0367 21.9147L19.25 13.6781L11.0367 5.44141"
            stroke="#525252"
            strokeWidth="2.625"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const Logout = () => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.9823 2.52742C13.8288 2.52893 13.6772 2.56079 13.5361 2.62122C13.395 2.68165 13.2671 2.76945 13.1598 2.87958C13.0524 2.98971 12.9678 3.12007 12.9107 3.26309C12.8536 3.40611 12.8251 3.55899 12.8269 3.71308V13.0908C12.827 13.4019 12.95 13.7002 13.1691 13.9202C13.3881 14.1401 13.6851 14.2636 13.9948 14.2636C14.3045 14.2636 14.6015 14.1401 14.8205 13.9202C15.0395 13.7002 15.1626 13.4019 15.1627 13.0908V3.71308C15.1645 3.55687 15.1352 3.40185 15.0766 3.25717C15.0179 3.1125 14.931 2.98103 14.821 2.87057C14.711 2.7601 14.5802 2.67289 14.4361 2.61395C14.2921 2.55501 14.1378 2.5256 13.9823 2.52742ZM20.7892 4.87815C20.7512 4.87705 20.7132 4.87781 20.6753 4.88044C20.4446 4.90011 20.225 4.98829 20.0443 5.13369C19.8636 5.2791 19.7301 5.47514 19.6607 5.69698C19.5914 5.91882 19.5892 6.15645 19.6546 6.3795C19.7201 6.60256 19.8501 6.80102 20.0281 6.94966C22.0492 8.67357 23.3279 11.2394 23.3279 14.1208C23.3279 19.3235 19.1686 23.5168 14.0005 23.5168C8.83235 23.5168 4.66395 19.3235 4.66396 14.1208C4.66397 11.2561 5.92638 8.70767 7.9273 6.98397C8.04387 6.88403 8.13971 6.76196 8.20932 6.62481C8.27894 6.48767 8.32099 6.33811 8.33305 6.18465C8.34512 6.03119 8.32697 5.87691 8.27965 5.73049C8.23233 5.58407 8.15676 5.44845 8.05725 5.33137C7.95774 5.21429 7.83624 5.11803 7.6997 5.04811C7.56316 4.97819 7.41424 4.93588 7.26145 4.92377C7.10866 4.91166 6.95499 4.92992 6.80922 4.97746C6.66344 5.025 6.52842 5.10089 6.41186 5.20084C3.91409 7.35256 2.32814 10.5598 2.32813 14.1208C2.32811 20.5881 7.56912 25.8607 14.0005 25.8607C20.4319 25.8607 25.6615 20.5881 25.6615 14.1208C25.6614 10.539 24.0598 7.3186 21.5367 5.16653C21.3292 4.98486 21.0645 4.88272 20.7892 4.87815Z"
            fill="#F03D43"
        />
    </svg>
);

export const Earth = () => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.0007 25.8582C20.444 25.8582 25.6673 20.6349 25.6673 14.1916C25.6673 7.74825 20.444 2.5249 14.0007 2.5249C7.55733 2.5249 2.33398 7.74825 2.33398 14.1916C2.33398 20.6349 7.55733 25.8582 14.0007 25.8582Z"
            stroke="#0A4C26"
            strokeWidth="1.80054"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.33353 3.69189H10.5002C8.2252 10.5052 8.2252 17.8786 10.5002 24.6919H9.33353"
            stroke="#0A4C26"
            strokeWidth="1.80054"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17.5 3.69189C19.775 10.5052 19.775 17.8786 17.5 24.6919"
            stroke="#0A4C26"
            strokeWidth="1.80054"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3.5 18.8581V17.6914C10.3133 19.9664 17.6867 19.9664 24.5 17.6914V18.8581"
            stroke="#0A4C26"
            strokeWidth="1.80054"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3.5 10.6916C10.3133 8.4166 17.6867 8.4166 24.5 10.6916"
            stroke="#0A4C26"
            strokeWidth="1.80054"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const Share = () => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.835 20.2978C19.7985 19.2162 18.8985 18.3654 17.8166 18.3897C16.7346 18.414 15.8737 19.3044 15.8859 20.3865C15.898 21.4687 16.7787 22.3395 17.861 22.3395C18.9696 22.3203 19.8532 21.4065 19.835 20.2978Z"
            stroke="#0A4C26"
            strokeWidth="1.80054"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9246 14.1733C12.9518 15.8368 11.6259 17.2077 9.96239 17.2358C8.29936 17.2071 6.9741 15.8364 7.00139 14.1733C6.9741 12.5103 8.29936 11.1396 9.96239 11.1108C11.6259 11.139 12.9518 12.5098 12.9246 14.1733Z"
            stroke="#0A4C26"
            strokeWidth="1.80054"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.8472 8.04807C18.8646 8.76496 18.4921 9.43499 17.874 9.79856C17.2559 10.1621 16.4893 10.1621 15.8712 9.79856C15.2531 9.43499 14.8806 8.76496 14.898 8.04807C14.8806 7.33118 15.2531 6.66115 15.8712 6.29758C16.4893 5.93401 17.2559 5.93401 17.874 6.29758C18.4921 6.66115 18.8646 7.33118 18.8472 8.04807Z"
            stroke="#0A4C26"
            strokeWidth="1.80054"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.8576 19.6808C16.2633 19.9684 16.8252 19.8726 17.1127 19.467C17.4002 19.0614 17.3045 18.4995 16.8989 18.2119L15.8576 19.6808ZM12.8155 15.3174C12.4099 15.0299 11.848 15.1256 11.5605 15.5313C11.2729 15.9369 11.3687 16.4988 11.7743 16.7863L12.8155 15.3174ZM16.0019 10.0591C16.3648 9.71929 16.3836 9.14958 16.0438 8.7866C15.704 8.42363 15.1343 8.40483 14.7714 8.74463L16.0019 10.0591ZM11.6844 11.6345C11.3214 11.9743 11.3026 12.544 11.6424 12.9069C11.9822 13.2699 12.5519 13.2887 12.9149 12.9489L11.6844 11.6345ZM16.8989 18.2119L12.8155 15.3174L11.7743 16.7863L15.8576 19.6808L16.8989 18.2119ZM14.7714 8.74463L11.6844 11.6345L12.9149 12.9489L16.0019 10.0591L14.7714 8.74463Z"
            fill="#0A4C26"
        />
    </svg>
);

export const RetiredMilitary = () => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.9796 5.07897C23.6514 4.75078 23.2063 4.56641 22.7422 4.56641H5.24219C4.77809 4.56641 4.33298 4.75078 4.00476 5.07897C3.67655 5.40716 3.49219 5.85228 3.49219 6.31641V12.7367C3.49219 22.5245 11.8021 25.7638 13.4317 26.3055C13.7941 26.4354 14.1903 26.4354 14.5527 26.3055C16.1822 25.7638 24.4922 22.5245 24.4922 12.7367V6.31641C24.4922 5.85228 24.3078 5.40716 23.9796 5.07897ZM5.24219 6.31641H22.7422V12.7367C22.7422 21.2965 15.5018 24.1478 13.9922 24.6477C12.4825 24.1478 5.24219 21.2965 5.24219 12.7367V6.31641Z"
            fill="#0A4C26"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.4972 12.5997C14.1958 12.3886 13.7946 12.3886 13.4934 12.5997L5.35587 18.2981C4.96002 18.5753 4.86377 19.1209 5.14097 19.5167C5.41817 19.9126 5.96382 20.0088 6.35967 19.7316L13.9953 14.3846L21.6308 19.7316C22.0267 20.0088 22.5723 19.9126 22.8495 19.5167C23.1267 19.1209 23.0305 18.5753 22.6346 18.2981L14.4972 12.5997Z"
            fill="#0A4C26"
        />
    </svg>
);

export const Star = () => (
    <svg
        width="33"
        height="33"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M22.127 12.5833L28.9491 13.6254L23.9026 18.7981C23.0728 19.6486 22.6961 20.8425 22.8874 22.0152L24.0841 29.3511L18.038 26.0088C16.911 25.3858 15.543 25.3857 14.4159 26.0086L8.36981 29.35L9.56651 22.014C9.7578 20.8413 9.38106 19.6475 8.55132 18.797L3.50574 13.6253L10.3267 12.5833C11.561 12.3948 12.62 11.6034 13.1504 10.4731L16.2264 3.91929L19.3034 10.4735C19.834 11.6036 20.8929 12.3948 22.127 12.5833Z"
            stroke="#0A4C26"
            strokeWidth="2.04202"
        />
    </svg>
);

export const Orders = () => (
    <svg
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.89355 4.0249V19.9849C4.89355 21.2916 5.50689 22.5316 6.56022 23.3182L13.5069 28.5182C14.9869 29.6249 17.0269 29.6249 18.5069 28.5182L25.4535 23.3182C26.5069 22.5316 27.1202 21.2916 27.1202 19.9849V4.0249H4.89355Z"
            stroke="#0A4C26"
            strokeWidth="2.01929"
            strokeMiterlimit="10"
        />
        <path
            d="M2.66602 4.0249H29.3327"
            stroke="#0A4C26"
            strokeWidth="2.01929"
            strokeMiterlimit="10"
            strokeLinecap="round"
        />
        <path
            d="M10.667 11.3584H21.3337"
            stroke="#0A4C26"
            strokeWidth="2.01929"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.667 18.0249H21.3337"
            stroke="#0A4C26"
            strokeWidth="2.01929"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const Camera = () => (
    <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.3125 12.3047C10.5551 12.3047 11.5625 11.2973 11.5625 10.0547C11.5625 8.81205 10.5551 7.80469 9.3125 7.80469C8.06986 7.80469 7.0625 8.81205 7.0625 10.0547C7.0625 11.2973 8.06986 12.3047 9.3125 12.3047Z"
            stroke="white"
            strokeWidth="1.05073"
        />
        <path
            d="M7.646 16.0547H10.979C13.3198 16.0547 14.4905 16.0547 15.3312 15.5035C15.694 15.2657 16.0065 14.9589 16.2507 14.6005C16.8125 13.7755 16.8125 12.6257 16.8125 10.3277C16.8125 8.02897 16.8125 6.87997 16.2507 6.05497C16.0065 5.69656 15.694 5.38972 15.3312 5.15197C14.7912 4.79722 14.1147 4.67047 13.079 4.62547C12.5847 4.62547 12.1595 4.25797 12.0627 3.78172C11.9888 3.43287 11.7967 3.12025 11.5189 2.89669C11.2411 2.67312 10.8946 2.55233 10.538 2.55472H8.087C7.346 2.55472 6.70775 3.06847 6.56225 3.78172C6.4655 4.25797 6.04025 4.62547 5.546 4.62547C4.511 4.67047 3.8345 4.79797 3.29375 5.15197C2.93121 5.38977 2.61903 5.6966 2.375 6.05497C1.8125 6.87997 1.8125 8.02897 1.8125 10.3277C1.8125 12.6257 1.8125 13.7747 2.37425 14.6005C2.61725 14.9575 2.92925 15.2642 3.29375 15.5035C4.1345 16.0547 5.30525 16.0547 7.646 16.0547Z"
            stroke="white"
            strokeWidth="1.05073"
        />
        <path
            d="M14.5625 7.80469H13.8125"
            stroke="white"
            strokeWidth="1.05073"
            strokeLinecap="round"
        />
    </svg>
);

export const Edit = () => (
    <svg
        width="33"
        height="33"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.7114 5.49104L6.76471 17.0777C6.35138 17.5177 5.95138 18.3844 5.87138 18.9844L5.37804 23.3044C5.20471 24.8644 6.32471 25.931 7.87138 25.6644L12.1647 24.931C12.7647 24.8244 13.6047 24.3844 14.018 23.931L24.9647 12.3444C26.858 10.3444 27.7114 8.06438 24.7647 5.27771C21.8314 2.51771 19.6047 3.49104 17.7114 5.49104Z"
            stroke="#0A4C26"
            strokeWidth="2.07901"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.8848 7.42432C16.4581 11.1043 19.4448 13.9176 23.1514 14.291"
            stroke="#0A4C26"
            strokeWidth="2.07901"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M4.03125 30.0249H28.0312"
            stroke="#0A4C26"
            strokeWidth="2.07901"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const Delete = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.0049 5.31858C14.4149 5.06191 11.8093 4.92969 9.21155 4.92969C7.67155 4.92969 6.13155 5.00747 4.59155 5.16302L3.00488 5.31858"
            stroke="#FF4B4B"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.2832 4.53266L7.45431 3.51377C7.57876 2.77488 7.67209 2.22266 8.98654 2.22266H11.0243C12.3388 2.22266 12.4399 2.80599 12.5565 3.52155L12.7276 4.53266"
            stroke="#FF4B4B"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.3323 7.77734L14.8268 15.6096C14.7412 16.8307 14.6712 17.7796 12.5012 17.7796H7.50787C5.33787 17.7796 5.26787 16.8307 5.18231 15.6096L4.67676 7.77734"
            stroke="#FF4B4B"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.70703 13.502H11.297"
            stroke="#FF4B4B"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.06055 10.3906H11.9494"
            stroke="#FF4B4B"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export const Warning = () => (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20 15V23.3333"
            stroke="#FF4B4B"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M20.0007 35.6828H9.9007C4.11737 35.6828 1.7007 31.5495 4.5007 26.4995L9.7007 17.1328L14.6007 8.33281C17.5674 2.98281 22.434 2.98281 25.4007 8.33281L30.3007 17.1495L35.5007 26.5161C38.3007 31.5661 35.8674 35.6995 30.1007 35.6995H20.0007V35.6828Z"
            stroke="#FF4B4B"
            strokeWidth="2.14286"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M19.9902 28.334H20.0052"
            stroke="#FF4B4B"
            strokeWidth="3.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const StoreIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.00977 11.2188V15.7087C3.00977 20.1987 4.80977 21.9987 9.29977 21.9987H14.6898C19.1798 21.9987 20.9798 20.1987 20.9798 15.7087V11.2188"
            stroke="#262626"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.0005 12C13.8305 12 15.1805 10.51 15.0005 8.68L14.3405 2H9.67048L9.00048 8.68C8.82048 10.51 10.1705 12 12.0005 12Z"
            stroke="#262626"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.3098 12C20.3298 12 21.8098 10.36 21.6098 8.35L21.3298 5.6C20.9698 3 19.9698 2 17.3498 2H14.2998L14.9998 9.01C15.1698 10.66 16.6598 12 18.3098 12Z"
            stroke="#262626"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.64037 12C7.29037 12 8.78037 10.66 8.94037 9.01L9.16037 6.8L9.64037 2H6.59037C3.97037 2 2.97037 3 2.61037 5.6L2.34037 8.35C2.14037 10.36 3.62037 12 5.64037 12Z"
            stroke="#262626"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12 17C10.33 17 9.5 17.83 9.5 19.5V22H14.5V19.5C14.5 17.83 13.67 17 12 17Z"
            stroke="#262626"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const EmailIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z"
            stroke="#292D32"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9"
            stroke="#292D32"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const UAEAvailableIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.9999 13.4314C13.723 13.4314 15.1199 12.0345 15.1199 10.3114C15.1199 8.58828 13.723 7.19141 11.9999 7.19141C10.2768 7.19141 8.87988 8.58828 8.87988 10.3114C8.87988 12.0345 10.2768 13.4314 11.9999 13.4314Z"
            stroke="#262626"
            strokeWidth="1.5"
        />
        <path
            d="M3.61971 8.49C5.58971 -0.169998 18.4197 -0.159997 20.3797 8.5C21.5297 13.58 18.3697 17.88 15.5997 20.54C13.5897 22.48 10.4097 22.48 8.38971 20.54C5.62971 17.88 2.46971 13.57 3.61971 8.49Z"
            stroke="#262626"
            strokeWidth="1.5"
        />
    </svg>
);

export const CompanyProfileIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.99983 22H15.9998C20.0198 22 20.7398 20.39 20.9498 18.43L21.6998 10.43C21.9698 7.99 21.2698 6 16.9998 6H6.99983C2.72983 6 2.02983 7.99 2.29983 10.43L3.04983 18.43C3.25983 20.39 3.97983 22 7.99983 22Z"
            stroke="#262626"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8 6V5.2C8 3.43 8 2 11.2 2H12.8C16 2 16 3.43 16 5.2V6"
            stroke="#262626"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14 13V14C14 14.01 14 14.01 14 14.02C14 15.11 13.99 16 12 16C10.02 16 10 15.12 10 14.03V13C10 12 10 12 11 12H13C14 12 14 12 14 13Z"
            stroke="#262626"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21.65 11C19.34 12.68 16.7 13.68 14 14.02"
            stroke="#262626"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.62012 11.2695C4.87012 12.8095 7.41012 13.7395 10.0001 14.0295"
            stroke="#262626"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const MobileNumberIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z"
            stroke="#262626"
            strokeWidth="1.5"
            strokeMiterlimit="10"
        />
    </svg>
);

export const CompanyTypeIcon = () => (
    <img
        src="/zabehatyIcons/company-type.png"
        alt="company-type"
        className="h-6 w-6"
    />
);

export const CommercialImageIcon = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.33301 8H10.6663"
            stroke="#0A4C26"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8 10.6654V5.33203"
            stroke="#0A4C26"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.99967 14.6654H9.99967C13.333 14.6654 14.6663 13.332 14.6663 9.9987V5.9987C14.6663 2.66536 13.333 1.33203 9.99967 1.33203H5.99967C2.66634 1.33203 1.33301 2.66536 1.33301 5.9987V9.9987C1.33301 13.332 2.66634 14.6654 5.99967 14.6654Z"
            stroke="#0A4C26"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const CloseIcon = () => (
    <svg
        width="32"
        height="33"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.07975 8.43587C8.70459 7.81103 9.71765 7.81103 10.3425 8.43587L15.9993 14.0927L21.6562 8.43587C22.281 7.81103 23.2941 7.81103 23.9189 8.43587C24.5438 9.06071 24.5438 10.0738 23.9189 10.6986L18.2621 16.3555L23.9189 22.0123C24.5438 22.6372 24.5438 23.6502 23.9189 24.2751C23.2941 24.8999 22.281 24.8999 21.6562 24.2751L15.9993 18.6182L10.3425 24.2751C9.71765 24.8999 8.70459 24.8999 8.07975 24.2751C7.45491 23.6502 7.45491 22.6372 8.07975 22.0123L13.7366 16.3555L8.07975 10.6986C7.45491 10.0738 7.45491 9.06071 8.07975 8.43587Z"
            fill="#262626"
        />
    </svg>
);

export const ChevronDownIcon = () => (
    <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_258_154458)">
            <path
                d="M4.66516 5.86704L6.10796 7.55893C6.32521 7.81369 6.67616 7.81369 6.89342 7.55893L8.33622 5.86704C8.68717 5.4555 8.43649 4.75 7.9407 4.75H5.0551C4.55931 4.75 4.31421 5.4555 4.66516 5.86704Z"
                fill="#525252"
            />
        </g>
        <defs>
            <clipPath id="clip0_258_154458">
                <rect
                    width="11.5"
                    height="11.5"
                    fill="white"
                    transform="translate(0.5 0.5)"
                />
            </clipPath>
        </defs>
    </svg>
);

export const PlusIcon = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.0286 1.20228C11.0286 1.08101 11.0048 0.96091 10.9585 0.84884C10.9121 0.73677 10.8442 0.634924 10.7585 0.549119C10.6728 0.463314 10.571 0.395228 10.459 0.348751C10.347 0.302273 10.2269 0.27831 10.1057 0.278236C9.98438 0.278161 9.86428 0.301975 9.75221 0.348315C9.64014 0.394656 9.53829 0.462615 9.45249 0.548315C9.36668 0.634015 9.2986 0.735778 9.25212 0.847791C9.20564 0.959804 9.18168 1.07987 9.18161 1.20115L9.18161 9.07709L1.3068 9.07596C1.06172 9.07596 0.82669 9.17331 0.653398 9.3466C0.480106 9.51989 0.382751 9.75493 0.382751 10C0.382751 10.2451 0.480106 10.4801 0.653398 10.6534C0.82669 10.8267 1.06172 10.924 1.3068 10.924L9.18161 10.9229L9.18047 18.7977C9.18047 18.9191 9.20437 19.0392 9.25081 19.1513C9.29725 19.2634 9.36531 19.3653 9.45112 19.4511C9.53692 19.5369 9.63879 19.605 9.7509 19.6514C9.86301 19.6979 9.98317 19.7218 10.1045 19.7218C10.3496 19.7218 10.5846 19.6244 10.7579 19.4511C10.8437 19.3653 10.9118 19.2634 10.9582 19.1513C11.0047 19.0392 11.0286 18.9191 11.0286 18.7977L11.0274 10.9229L18.9034 10.9229C19.1483 10.9228 19.3831 10.8253 19.5562 10.652C19.7293 10.4787 19.8264 10.2438 19.8263 9.99886C19.8261 9.75394 19.7287 9.51911 19.5554 9.34604C19.3821 9.17296 19.1472 9.07581 18.9022 9.07596L11.0274 9.07709L11.0286 1.20228Z"
            fill="#FAFAFA"
        />
    </svg>
);

export const Buthcering = () => (
    <svg
        width="131"
        height="96"
        viewBox="0 0 94 94"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M86.9193 19.471C85.1603 20.4371 83.1356 20.8072 81.1486 20.5257C79.1617 20.2442 77.3194 19.3264 75.898 17.9098C75.6637 17.6835 75.3628 17.5387 75.0398 17.497C74.7168 17.4552 74.389 17.5187 74.1049 17.6779L62.8363 23.8764V23.8609L57.6271 14.37L77.6447 3.39504C79.7848 2.18049 82.3115 1.84012 84.6967 2.44506C87.0819 3.05001 89.141 4.55345 90.4436 6.64114C91.0727 7.7104 91.4809 8.895 91.644 10.1248C91.8072 11.3547 91.7219 12.6047 91.3933 13.801C91.0646 14.9973 90.4994 16.1155 89.7309 17.0894C88.9624 18.0633 88.0063 18.8731 86.9193 19.471Z"
            fill="#CB8252"
        />
        <path
            d="M61.8917 58.5773C63.967 57.0998 65.9398 55.4833 67.7965 53.739C68.2917 53.2696 68.6167 52.6488 68.7203 51.9743C68.8238 51.2999 68.7 50.6101 68.3685 50.0138L59.6813 34.1697L50.8395 18.0938L6.07423 42.6405C4.27883 43.6287 2.94943 45.2895 2.37838 47.2578C1.80733 49.226 2.04139 51.3405 3.02908 53.1362L16.8327 78.2858C17.1555 78.887 17.6703 79.3629 18.2951 79.6375C18.9199 79.912 19.6186 79.9695 20.2798 79.8006C23.3245 79.0193 26.3018 77.9958 29.1834 76.74L61.8917 58.5773Z"
            fill="#7F8C8D"
        />
        <path
            d="M67.7985 53.7396C65.9417 55.4839 63.969 57.1004 61.8936 58.5779L29.1853 76.7406C26.3038 77.9964 23.3264 79.0199 20.2817 79.8012C19.6205 79.9701 18.9218 79.9126 18.297 79.6381C17.6722 79.3635 17.1574 78.8876 16.8347 78.2864L14.1914 73.479C14.1914 73.479 16.881 73.7109 25.7846 69.8619L57.0863 52.6112C64.5678 47.4638 65.7426 45.207 65.7426 45.207L68.3704 50.0144C68.702 50.6107 68.8257 51.3005 68.7222 51.9749C68.6186 52.6494 68.2937 53.2702 67.7985 53.7396Z"
            fill="#95A5A5"
        />
        <path
            d="M88.633 84.0977H5.16184C3.45444 84.0977 2.07031 85.4818 2.07031 87.1892V88.7349C2.07031 90.4423 3.45444 91.8265 5.16184 91.8265H88.633C90.3404 91.8265 91.7245 90.4423 91.7245 88.7349V87.1892C91.7245 85.4818 90.3404 84.0977 88.633 84.0977Z"
            fill="#CB8252"
        />
        <path
            d="M83.9354 14.1545C83.3994 14.4499 82.7878 14.5796 82.1781 14.5271C81.5684 14.4747 80.988 14.2425 80.5103 13.8599C80.0327 13.4773 79.6794 12.9616 79.4951 12.378C79.3108 11.7944 79.3038 11.1693 79.4751 10.5818C79.6464 9.99428 79.9882 9.47081 80.4572 9.07769C80.9262 8.68457 81.5013 8.4395 82.1097 8.3735C82.7181 8.3075 83.3324 8.42356 83.8748 8.70696C84.4172 8.99037 84.8633 9.42837 85.1565 9.9655C85.5484 10.6833 85.6399 11.5271 85.4111 12.3122C85.1822 13.0973 84.6516 13.7598 83.9354 14.1545Z"
            fill="#A56A43"
        />
        <path
            d="M13.3957 52.7539C12.8598 53.0467 12.2491 53.1741 11.6408 53.1202C11.0325 53.0663 10.4538 52.8334 9.9777 52.451C9.5016 52.0685 9.14943 51.5536 8.96562 50.9712C8.78181 50.3889 8.7746 49.7651 8.9449 49.1786C9.1152 48.5922 9.45538 48.0693 9.92252 47.6759C10.3897 47.2826 10.9628 47.0364 11.5697 46.9684C12.1766 46.9005 12.7901 47.0138 13.3326 47.294C13.8752 47.5743 14.3226 48.009 14.6184 48.5433C15.0112 49.2645 15.1031 50.1117 14.8741 50.9004C14.6451 51.689 14.1137 52.3552 13.3957 52.7539Z"
            fill="#3F5C6C"
        />
        <path
            d="M64.1643 35.4823C63.3942 35.8954 62.4925 35.9892 61.6539 35.7434C60.8152 35.4976 60.1069 34.9319 59.6816 34.1684L50.8398 18.0925L57.6257 14.3672L62.835 23.8582V23.8736L65.6792 31.417C65.9612 32.1632 65.9631 32.9863 65.6846 33.7339C65.406 34.4814 64.8659 35.1026 64.1643 35.4823Z"
            fill="#547580"
        />
        <path
            d="M77.813 73.2747C77.813 79.4578 73.1757 84.0951 65.4376 84.0951H44.4245C39.4868 84.1552 34.7206 82.2857 31.1406 78.8844C27.5606 75.4831 25.4496 70.8188 25.2571 65.8845C25.1826 61.858 26.4205 57.9167 28.7838 54.6559C31.1471 51.3951 34.5074 48.9921 38.357 47.8097C42.2066 46.6273 46.3365 46.7299 50.1227 48.1019C53.9089 49.474 57.1457 52.0409 59.3442 55.415C62.1039 59.365 65.9431 62.4363 70.4026 64.2614C73.6147 65.7005 77.813 67.914 77.813 73.2747Z"
            fill="#ECF0F1"
        />
        <path
            d="M44.424 77.9135C41.1053 77.9789 37.8919 76.7472 35.4672 74.4803C33.0424 72.2135 31.5975 69.0902 31.4395 65.7747C31.3907 63.091 32.2164 60.4643 33.7918 58.2912C35.3673 56.1182 37.607 54.5167 40.1728 53.7286C42.7386 52.9405 45.4912 53.0087 48.0148 53.9227C50.5385 54.8368 52.6963 56.5472 54.1623 58.7955C57.5573 63.7534 62.3226 67.6153 67.8763 69.9096C71.6294 71.5759 71.6294 72.845 71.6294 74.0491C71.6294 77.4498 67.7495 77.9135 65.4371 77.9135H44.424Z"
            fill="#FB7B76"
        />
        <path
            d="M47.671 71.7327C49.8053 71.7327 51.5355 70.0026 51.5355 67.8683C51.5355 65.7341 49.8053 64.0039 47.671 64.0039C45.5368 64.0039 43.8066 65.7341 43.8066 67.8683C43.8066 70.0026 45.5368 71.7327 47.671 71.7327Z"
            fill="#ECF0F1"
        />
    </svg>
);

export const DeliveryCar = () => (
    <svg
        width="131"
        height="96"
        viewBox="0 0 131 96"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M109.558 90.9279C113.315 87.7731 115.63 83.6149 117.121 79.716C120.578 70.6784 110.586 56.8038 114.513 52.0338C122.959 41.7756 122.57 32.4279 114.966 24.9102C110.964 20.9544 105.3 19.2584 100.278 18.588C92.9539 17.611 88.8089 16.4676 83.7378 12.9267C63.0184 -1.5418 52.665 1.0036 38.2684 8.11781C27.4761 13.4511 20.2823 19.7128 19.5843 30.2416C18.3643 48.6407 -1.60205 60.838 14.1852 75.8296C19.4335 80.8133 21.916 86.6961 25.9975 90.9279H109.558Z"
            fill="#F1F1F1"
        />
        <path
            d="M82.4378 31.9437C87.6232 33.0643 91.4808 27.4521 95.7509 26.3566C107.237 23.4095 113.157 33.6672 109.265 39.7104C107.749 42.063 107.372 43.8937 108.034 46.9192C109.503 53.6281 102.591 58.4956 96.9188 56.0502C91.2186 53.5928 89.4006 54.2244 85.6369 56.207C82.9395 57.6282 80.524 55.2085 76.9597 50.3787C71.4223 42.8754 72.7118 29.8419 82.4378 31.9437Z"
            fill="white"
        />
        <path
            d="M102.153 39.0234H100.789V51.766H102.153V39.0234Z"
            fill="#F0F0F0"
        />
        <path
            d="M106.818 38.332H82.3984V39.6963H106.818V38.332Z"
            fill="#F0F0F0"
        />
        <path
            d="M98.0133 33.1484H96.6484V39.0187H98.0133V33.1484Z"
            fill="#EEF6FF"
        />
        <path
            d="M105.625 45.5508H77.6328V46.9151H105.625V45.5508Z"
            fill="#F0F0F0"
        />
        <path
            d="M96.8174 46.4727H95.4531V51.761H96.8174V46.4727Z"
            fill="#F0F0F0"
        />
        <path
            d="M87.1416 46.4727H85.7773V51.761H87.1416V46.4727Z"
            fill="#F0F0F0"
        />
        <path
            d="M90.4307 35.8477H89.0664V46.2316H90.4307V35.8477Z"
            fill="#F0F0F0"
        />
        <path
            d="M82.2668 24.1163C82.2668 24.1683 82.2668 24.2192 82.2686 24.2707C82.3764 32.8216 91.596 41.9485 94.1911 43.7749C96.7862 41.9485 106.006 32.821 106.114 24.2707C106.115 24.2186 106.115 24.1677 106.115 24.1163C106.115 17.5301 100.777 12.1914 94.1905 12.1914C87.6043 12.1914 82.2656 17.5301 82.2656 24.1163H82.2668ZM88.2263 23.8319C88.2263 20.5376 90.8968 17.8665 94.1917 17.8665C97.4866 17.8665 100.157 20.537 100.157 23.8319C100.157 27.1268 97.486 29.7973 94.1917 29.7973C90.8974 29.7973 88.2263 27.1268 88.2263 23.8319Z"
            fill="#F03D43"
        />
        <path
            d="M68.2609 92.202C97.6588 92.202 121.49 91.6033 121.49 90.8647C121.49 90.1261 97.6588 89.5273 68.2609 89.5273C38.863 89.5273 15.0312 90.1261 15.0312 90.8647C15.0312 91.6033 38.863 92.202 68.2609 92.202Z"
            fill="#D0D0D0"
        />
        <path
            d="M18.4316 83.0048C21.6894 84.7977 29.8925 85.273 29.8925 85.273H100.896C106.942 85.273 110.347 84.9498 113.75 84.5379C117.753 84.0524 120.104 83.4448 121.223 83.0958C121.702 82.9455 122.028 82.5007 122.028 81.9979V75.9894C122.028 75.4865 121.703 75.0363 121.222 74.8915C121.218 74.8897 121.213 74.8885 121.208 74.8867C120.979 73.5062 120.833 72.5945 120.588 71.6678C120.49 71.3026 120.379 70.9351 120.241 70.5364C118.872 66.5896 114.011 65.958 109.911 65.0469C105.81 64.1357 99.2637 63.5892 99.2637 63.5892C99.2637 63.5892 90.3069 56.3433 84.4469 52.3719C82.9018 51.3243 81.5716 50.5042 80.6779 50.1049C76.3958 48.1916 66.3734 47.6451 51.2495 47.3715C38.7008 47.1446 26.5593 47.7331 22.179 47.9402C21.308 47.9821 20.5519 48.5526 20.2742 49.3787C19.8378 50.6778 19.2116 52.849 18.7788 55.6626C18.7381 55.9284 18.7004 56.2128 18.6668 56.5139C18.607 57.0515 18.5573 57.6411 18.5172 58.2643C18.1807 63.4814 18.5052 71.0608 18.5052 71.0608C16.7356 76.485 18.1011 82.8234 18.4328 83.0054L18.4316 83.0048Z"
            fill="#C2BF70"
        />
        <path
            d="M17.8233 82.1166C17.9011 82.4878 18.1011 82.8206 18.4327 83.0032C21.6905 84.7961 29.8936 85.2714 29.8936 85.2714H100.897C106.944 85.2714 110.348 84.9482 113.751 84.5363C105.273 80.6368 97.3714 75.4999 79.4841 74.7109C53.893 73.5825 54.4264 60.601 36.0374 58.5854C30.4581 57.9742 24.4406 57.245 18.6662 56.5117C18.6063 57.0493 18.5566 57.639 18.5165 58.2621C18.1801 63.4793 18.5046 71.0586 18.5046 71.0586C18.0813 71.7416 17.0032 78.2099 17.8227 82.1166H17.8233Z"
            fill="#E2E09E"
        />
        <path
            d="M18.4086 67.5954C18.4266 68.4951 18.4493 69.2752 18.4685 69.866C18.4918 70.6155 18.511 71.0615 18.511 71.0615C18.3476 71.3255 20.38 71.8714 20.38 71.8714C20.6481 70.33 21.0169 67.6062 21.0169 64.2741C21.0169 60.2232 19.3012 58.7326 18.5236 58.2656C18.444 59.4976 18.4015 60.8613 18.3835 62.2256C18.3697 63.1954 18.3685 64.1652 18.3745 65.0889C18.3805 65.9827 18.3931 66.8321 18.4086 67.5954Z"
            fill="#F34135"
        />
        <path
            d="M20.3201 64.3703C20.3255 64.766 20.0058 65.0898 19.6095 65.0898H18.3745C18.3685 64.1661 18.3697 63.1958 18.3835 62.2266L19.8065 62.6666C20.101 62.7576 20.3033 63.0281 20.3069 63.3358L20.3207 64.3703H20.3201Z"
            fill="white"
        />
        <path
            d="M20.3216 69.1867C20.3264 69.5597 20.0253 69.8644 19.6529 69.8644H18.47C18.4503 69.2735 18.4281 68.4935 18.4102 67.5938H19.641C20.0073 67.5938 20.3048 67.8883 20.3102 68.254L20.3222 69.1867H20.3216Z"
            fill="white"
        />
        <path
            d="M107.613 67.3767V68.3189C107.613 68.5925 107.755 68.8463 107.988 68.9894C108.212 69.1271 108.526 69.3175 108.902 69.5389C110.289 70.3555 112.51 71.5851 114.105 72.082C116.587 72.8566 118.683 71.9682 118.683 71.9682C118.683 71.9682 119.071 68.9619 114.834 67.8909C111.359 67.0127 109.646 66.5787 108.388 66.5901C108.049 66.5931 107.763 66.8134 107.657 67.1175C107.629 67.1995 107.613 67.2863 107.613 67.3773V67.3767Z"
            fill="#F2EEFF"
        />
        <path
            d="M107.613 67.3847V68.327C107.613 68.6005 107.755 68.8544 107.988 68.9974C108.212 69.1351 108.526 69.3255 108.902 69.547C110.381 69.4913 111.576 69.2573 111.576 68.57C111.576 67.7589 110.087 67.1016 108.251 67.1016C108.048 67.1016 107.849 67.1099 107.657 67.1255C107.629 67.2075 107.613 67.2943 107.613 67.3853V67.3847Z"
            fill="white"
        />
        <path
            d="M17.1865 83.1487C17.2392 83.524 17.4643 83.8527 17.796 84.0352C21.0537 85.8282 29.898 85.818 29.898 85.818H100.902C104.157 85.818 106.992 85.724 109.445 85.5731C116.912 85.1146 119.67 84.1274 121.228 83.6407C121.708 83.4905 122.033 83.0457 122.033 82.5428V75.9878C122.033 75.4849 121.709 75.0347 121.228 74.8899C121.224 74.8881 121.219 74.8869 121.213 74.8851C120.985 73.5046 120.838 72.5929 120.593 71.6662H113.454C112.714 71.6662 111.995 71.9039 111.401 72.3439L107.165 76.3727L103.606 79.0151L94.555 80.3518L92.9147 83.6928L91.5672 83.6707L35.5773 82.7206L27.2562 76.7684C27.2562 76.7684 23.0041 71.2406 18.5095 71.0586C16.1778 74.8186 16.9543 81.4743 17.1865 83.1487Z"
            fill="#312E6A"
        />
        <path
            d="M91.6895 83.9389C91.6375 83.6725 91.5968 83.4019 91.5686 83.1277C91.5357 82.8152 91.5195 82.4979 91.5195 82.1777C91.5195 77.1455 95.5986 73.0664 100.63 73.0664C103.194 73.0664 105.511 74.1254 107.166 75.8297C108.76 77.47 109.741 79.7089 109.741 82.1771C109.741 82.7793 109.682 83.3684 109.571 83.9383H91.6895V83.9389Z"
            fill="#312E6A"
        />
        <path
            d="M100.627 90.1952C105.055 90.1952 108.644 86.6057 108.644 82.1777C108.644 77.7497 105.055 74.1602 100.627 74.1602C96.1989 74.1602 92.6094 77.7497 92.6094 82.1777C92.6094 86.6057 96.1989 90.1952 100.627 90.1952Z"
            fill="#3E3D80"
        />
        <path
            d="M104.234 85.7836C106.226 83.791 106.226 80.5605 104.234 78.568C102.241 76.5755 99.0105 76.5755 97.0179 78.568C95.0254 80.5605 95.0254 83.791 97.0179 85.7836C99.0105 87.7761 102.241 87.7761 104.234 85.7836Z"
            fill="#C5D9EA"
        />
        <path
            d="M101.593 86.2993C103.867 85.7625 105.276 83.4836 104.739 81.2093C104.202 78.935 101.924 77.5264 99.6493 78.0632C97.3749 78.6 95.9664 80.8789 96.5032 83.1532C97.04 85.4275 99.3188 86.836 101.593 86.2993Z"
            fill="#D4E7F4"
        />
        <path
            d="M102.2 82.8242C102.559 81.9564 102.147 80.9615 101.279 80.6021C100.411 80.2426 99.4165 80.6547 99.0571 81.5225C98.6976 82.3903 99.1097 83.3851 99.9775 83.7446C100.845 84.104 101.84 83.692 102.2 82.8242Z"
            fill="#DCEEF9"
        />
        <path
            d="M22.4788 83.131C22.4458 82.8185 22.4297 82.5012 22.4297 82.181C22.4297 81.6518 22.4746 81.1334 22.5614 80.6287C23.2983 76.3365 27.038 73.0703 31.5403 73.0703C34.1043 73.0703 36.421 74.1293 38.0757 75.8336C39.6698 77.4739 40.651 79.7128 40.651 82.181C40.651 82.7832 40.5923 83.3723 40.481 83.9422C40.481 83.9422 22.5069 83.4052 22.4788 83.131Z"
            fill="#312E6A"
        />
        <path
            d="M31.5136 90.1952C35.9416 90.1952 39.5312 86.6057 39.5312 82.1777C39.5312 77.7497 35.9416 74.1602 31.5136 74.1602C27.0857 74.1602 23.4961 77.7497 23.4961 82.1777C23.4961 86.6057 27.0857 90.1952 31.5136 90.1952Z"
            fill="#3E3D80"
        />
        <path
            d="M35.1202 85.7836C37.1128 83.791 37.1128 80.5605 35.1202 78.568C33.1277 76.5755 29.8972 76.5755 27.9047 78.568C25.9121 80.5605 25.9121 83.791 27.9047 85.7836C29.8972 87.7761 33.1277 87.7761 35.1202 85.7836Z"
            fill="#C5D9EA"
        />
        <path
            d="M32.4799 86.2993C34.7542 85.7625 36.1627 83.4836 35.626 81.2093C35.0892 78.935 32.8103 77.5264 30.536 78.0632C28.2617 78.6 26.8531 80.8789 27.3899 83.1532C27.9267 85.4275 30.2056 86.836 32.4799 86.2993Z"
            fill="#D4E7F4"
        />
        <path
            d="M33.0863 82.8242C33.4458 81.9564 33.0337 80.9615 32.1659 80.6021C31.2981 80.2426 30.3033 80.6547 29.9438 81.5225C29.5844 82.3903 29.9965 83.3851 30.8642 83.7446C31.732 84.104 32.7269 83.692 33.0863 82.8242Z"
            fill="#DCEEF9"
        />
        <path
            d="M67.6542 71.497L67.9878 71.4795C68.4735 71.4542 68.8466 71.0399 68.8213 70.5542L68.6644 67.5513C68.639 67.0657 68.2248 66.6925 67.7391 66.7179L67.4055 66.7353C66.9198 66.7607 66.5467 67.1749 66.572 67.6606L66.7289 70.6635C66.7542 71.1492 67.1685 71.5223 67.6542 71.497Z"
            fill="#312E6A"
        />
        <path
            d="M80.3595 52.5977H84.7763C90.6363 56.6109 99.2651 63.5916 99.2651 63.5916L94.0108 63.8652C84.5404 63.2731 75.474 53.5962 80.3589 52.5983L80.3595 52.5977Z"
            fill="#CADCFF"
        />
        <path
            d="M80.8533 52.5983L95.745 63.8652C95.745 63.8652 93.3043 64.6602 89.0636 64.8673C81.2125 65.251 71.5313 64.4285 68.6686 64.1591C68.1861 64.1136 67.8048 63.7293 67.7635 63.2462L66.9452 53.686C66.8949 53.1005 67.3564 52.5977 67.9443 52.5977H80.8533V52.5983Z"
            fill="#312E6A"
        />
        <path
            d="M78.6758 53.8746L89.5022 63.4247C89.5022 63.4247 90.0218 64.0185 86.3988 64.137C83.2877 64.2388 75.864 63.5528 73.828 63.357C73.5221 63.3277 73.2791 63.0882 73.2438 62.7829L72.2644 54.2248C72.2369 53.9842 72.4248 53.7734 72.6667 53.7734H78.4082C78.507 53.7734 78.6022 53.8094 78.6764 53.8746H78.6758Z"
            fill="#CADCFF"
        />
        <path
            d="M75.8203 53.7734L81.7869 63.9988C83.5834 64.1083 85.2746 64.1748 86.4006 64.137C90.023 64.0185 89.504 63.4247 89.504 63.4247L78.6776 53.8746C78.604 53.81 78.5088 53.7734 78.4094 53.7734H75.8203Z"
            fill="#B2C8FF"
        />
        <path
            d="M86.2621 60.6244L86.3902 64.6012C86.4028 64.9951 86.7326 65.304 87.1259 65.2914L88.0604 65.2615C88.9613 65.2327 89.6677 64.479 89.639 63.5781L89.5564 61.0052C89.5348 60.3425 88.9817 59.8228 88.3196 59.8444L86.9535 59.8881C86.559 59.9007 86.2495 60.2305 86.2621 60.6238V60.6244Z"
            fill="#3E3D80"
        />
        <path
            d="M89.8568 64.5312H88.0346V62.284H89.8568C90.477 62.284 90.9805 62.7874 90.9805 63.4076C90.9805 64.0278 90.477 64.5312 89.8568 64.5312Z"
            fill="#3E3D80"
        />
        <path
            d="M86.3359 62.7029L86.3838 64.1899L88.676 64.1163C89.0867 64.1031 89.4093 63.7589 89.3962 63.3483C89.3896 63.1435 89.301 62.9603 89.1615 62.8298C89.0226 62.6993 88.834 62.6227 88.6281 62.6293L86.3359 62.7029Z"
            fill="#3E3D80"
        />
        <path
            opacity="0.4"
            d="M64.4154 83.2168L62.4615 52.7498C62.4525 52.6103 62.5585 52.49 62.6973 52.481C62.8368 52.472 62.9571 52.578 62.9661 52.7175L64.9105 83.2222L64.4148 83.2162L64.4154 83.2168Z"
            fill="#C2BF70"
        />
        <path
            d="M50.4667 80.3127L87.1882 81.7644C86.4268 81.0856 85.4606 80.6803 84.4423 80.6132L46.7539 78.1211C47.5447 79.4291 48.9389 80.2522 50.4661 80.3127H50.4667Z"
            fill="#C2BF70"
        />
    </svg>
);


export const ShareIcon = () => (
    <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M19.9227 16.4837C18.7419 16.4837 17.6849 17.0223 16.9835 17.8667L12.4209 15.2762C12.5628 14.877 12.6408 14.4477 12.6408 14.0003C12.6408 13.553 12.5628 13.1237 12.4209 12.7244L16.9831 10.1333C17.6844 10.978 18.7417 11.5168 19.9225 11.5168C22.0289 11.5168 23.7425 9.80252 23.7425 7.69531C23.7427 5.58873 22.029 3.875 19.9227 3.875C17.8161 3.875 16.1023 5.58873 16.1023 7.69522C16.1023 8.14262 16.1802 8.57201 16.3222 8.97137L11.7597 11.5625C11.0584 10.7183 10.0015 10.1799 8.82094 10.1799C6.71409 10.1799 5 11.8936 5 14.0002C5 16.1067 6.71409 17.8204 8.82094 17.8204C10.0015 17.8204 11.0584 17.2819 11.7597 16.4378L16.3222 19.0284C16.1802 19.4277 16.1022 19.8572 16.1022 20.3047C16.1022 22.4112 17.816 24.1249 19.9226 24.1249C22.0289 24.1249 23.7426 22.4111 23.7426 20.3047C23.7427 18.1978 22.029 16.4837 19.9227 16.4837ZM19.9227 5.21183C21.2919 5.21183 22.4058 6.32586 22.4058 7.69522C22.4058 9.0653 21.2919 10.1799 19.9227 10.1799C18.5533 10.1799 17.4391 9.0653 17.4391 7.69522C17.4391 6.32586 18.5533 5.21183 19.9227 5.21183ZM8.82103 16.4837C7.45131 16.4837 6.33692 15.3695 6.33692 14.0003C6.33692 12.6308 7.45131 11.5168 8.82103 11.5168C10.1902 11.5168 11.3041 12.6308 11.3041 14.0003C11.3041 15.3695 10.1901 16.4837 8.82103 16.4837ZM19.9227 22.7882C18.5533 22.7882 17.4391 21.674 17.4391 20.3048C17.4391 18.935 18.5533 17.8205 19.9227 17.8205C21.2919 17.8205 22.4058 18.935 22.4058 20.3048C22.4058 21.674 21.2919 22.7882 19.9227 22.7882Z"
        fill="#648C74"
    />
</svg>
);
export const CartIcon = () => (
    <svg
	width="28"
	height="28"
	viewBox="0 0 28 28"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M12.2232 20.6949C12.2084 21.3294 11.6841 21.8329 11.0495 21.822C10.415 21.8111 9.90821 21.29 9.91511 20.6554C9.92201 20.0208 10.44 19.5108 11.0746 19.5137C11.7173 19.5242 12.2307 20.0522 12.2232 20.6949Z"
		stroke="#648C74"
		strokeWidth="1.28571"
		strokeLinecap="round"
		strokeLinejoin="round"
	/>
	<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M18.3765 20.6949C18.3617 21.3287 17.8386 21.8319 17.2047 21.822C16.5709 21.8122 16.0636 21.293 16.0684 20.6591C16.0732 20.0252 16.5884 19.5138 17.2223 19.5137C17.532 19.5172 17.8276 19.6437 18.0441 19.8652C18.2606 20.0868 18.3801 20.3852 18.3765 20.6949Z"
		stroke="#648C74"
		strokeWidth="1.28571"
		strokeLinecap="round"
		strokeLinejoin="round"
	/>
	<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M22.5838 9.27539H11.4542L10.6847 15.5754C10.6799 15.9883 10.8393 16.3863 11.128 16.6817C11.4166 16.977 11.8107 17.1456 12.2237 17.1504H19.0457C19.5903 17.1482 20.0928 16.8569 20.3653 16.3854L23.9001 11.6604C24.1889 11.1791 24.1997 10.5804 23.9285 10.089C23.6572 9.59756 23.145 9.28761 22.5838 9.27539V9.27539Z"
		stroke="#648C74"
		strokeWidth="1.28571"
		strokeLinecap="round"
		strokeLinejoin="round"
	/>
	<path
		d="M10.83 9.42752C10.9143 9.77242 11.2621 9.98374 11.607 9.8995C11.9519 9.81527 12.1633 9.46739 12.079 9.12248L10.83 9.42752ZM10.7177 6.25775L11.3422 6.10523L11.3419 6.1043L10.7177 6.25775ZM10.5489 6.125L10.5511 5.48214H10.5489V6.125ZM7.60815 5.48214C7.25311 5.48214 6.9653 5.76996 6.9653 6.125C6.9653 6.48004 7.25311 6.76786 7.60815 6.76786V5.48214ZM8.22087 11.1426C8.57592 11.1426 8.86373 10.8548 8.86373 10.4998C8.86373 10.1447 8.57592 9.8569 8.22087 9.8569V11.1426ZM5 9.8569C4.64496 9.8569 4.35714 10.1447 4.35714 10.4998C4.35714 10.8548 4.64496 11.1426 5 11.1426V9.8569ZM8.22249 13.7683C8.57753 13.7683 8.86535 13.4805 8.86535 13.1255C8.86535 12.7704 8.57753 12.4826 8.22249 12.4826V13.7683ZM7.17399 12.4826C6.81895 12.4826 6.53113 12.7704 6.53113 13.1255C6.53113 13.4805 6.81895 13.7683 7.17399 13.7683V12.4826ZM12.079 9.12248L11.3422 6.10523L10.0932 6.41027L10.83 9.42752L12.079 9.12248ZM11.3419 6.1043C11.2524 5.73996 10.9262 5.4834 10.5511 5.48215L10.5468 6.76785C10.3317 6.76713 10.1447 6.62006 10.0934 6.4112L11.3419 6.1043ZM10.5489 5.48214H7.60815V6.76786H10.5489V5.48214ZM8.22087 9.8569H5V11.1426H8.22087V9.8569ZM8.22249 12.4826H7.17399V13.7683H8.22249V12.4826Z"
		fill="#648C74"
	/>
</svg>
);




export const PlusIconSmall = () => (
    <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.22196 8.65486H12.7775C13.4154 8.65486 13.9331 8.13717 13.9331 7.49931C13.9331 6.86144 13.4154 6.34375 12.7775 6.34375H1.22196C0.584095 6.34375 0.0664062 6.86144 0.0664062 7.49931C0.0664062 8.13717 0.584095 8.65486 1.22196 8.65486Z"
            fill="white"
            />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.15095 13.2775V1.72196C8.15095 1.0841 7.63327 0.566406 6.9954 0.566406C6.35753 0.566406 5.83984 1.0841 5.83984 1.72196V13.2775C5.83984 13.9154 6.35753 14.4331 6.9954 14.4331C7.63327 14.4331 8.15095 13.9154 8.15095 13.2775Z"
            fill="white"
            />
    </svg>

);
export const MinusIconSmall = () => (
    <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.22196 8.65486H12.7775C13.4154 8.65486 13.9331 8.13717 13.9331 7.49931C13.9331 6.86144 13.4154 6.34375 12.7775 6.34375H1.22196C0.584095 6.34375 0.0664062 6.86144 0.0664062 7.49931C0.0664062 8.13717 0.584095 8.65486 1.22196 8.65486Z"
            fill="white"
        />
    </svg>
);

export const Clock = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.70826 2.40625C8.1868 2.40625 6.69951 2.85742 5.43446 3.70269C4.16942 4.54797 3.18343 5.7494 2.6012 7.15504C2.01896 8.56069 1.86662 10.1074 2.16344 11.5996C2.46026 13.0919 3.19291 14.4626 4.26875 15.5384C5.34458 16.6142 6.71528 17.3469 8.20751 17.6437C9.69973 17.9405 11.2465 17.7882 12.6521 17.206C14.0578 16.6237 15.2592 15.6377 16.1045 14.3727C16.9497 13.1076 17.4009 11.6203 17.4009 10.0989C17.3987 8.05933 16.5876 6.10393 15.1454 4.66175C13.7032 3.21957 11.7478 2.4084 9.70826 2.40625ZM9.70826 16.608C8.42088 16.608 7.1624 16.2263 6.09197 15.5111C5.02155 14.7958 4.18725 13.7792 3.69459 12.5898C3.20193 11.4004 3.07303 10.0917 3.32418 8.82901C3.57534 7.56636 4.19528 6.40654 5.1056 5.49622C6.01592 4.5859 7.17574 3.96596 8.43839 3.7148C9.70104 3.46365 11.0098 3.59255 12.1992 4.08521C13.3886 4.57787 14.4052 5.41217 15.1204 6.48259C15.8357 7.55302 16.2174 8.8115 16.2174 10.0989C16.2155 11.8246 15.529 13.4791 14.3088 14.6994C13.0885 15.9197 11.434 16.6061 9.70826 16.608ZM14.4422 10.0989C14.4422 10.2558 14.3799 10.4063 14.2689 10.5173C14.1579 10.6283 14.0074 10.6906 13.8505 10.6906H9.70826C9.55133 10.6906 9.40081 10.6283 9.28984 10.5173C9.17887 10.4063 9.11652 10.2558 9.11652 10.0989V5.9567C9.11652 5.79976 9.17887 5.64925 9.28984 5.53827C9.40081 5.4273 9.55133 5.36496 9.70826 5.36496C9.8652 5.36496 10.0157 5.4273 10.1267 5.53827C10.2377 5.64925 10.3 5.79976 10.3 5.9567V9.50714H13.8505C14.0074 9.50714 14.1579 9.56949 14.2689 9.68046C14.3799 9.79144 14.4422 9.94195 14.4422 10.0989Z"
            fill="white"
        />
    </svg>

);