import { useTranslation } from "react-i18next";
import useModalsStore from "../../store/modalsStore";
import useUserStore from "../../store/userStore";
import LanguageSwitcher from "./language-switcher";
import { getDefaultAddressId } from "../../lib/middleware";

export const TopHeader = () => {
    const { t } = useTranslation();
    const setMapModalOpen = useModalsStore((state) => state.setMapModalOpen);
    const addresses = useUserStore((state) => state.user?.address);
    const defaultAddressId = getDefaultAddressId();
    const defaultAddress = useUserStore((state) => state.mainAddress);

    return (
        <div className="relative flex items-center justify-between gap-4 bg-[rgba(245,245,245,1)] px-4 py-4 xl:px-20">
            <button
                onClick={() => setMapModalOpen(true)}
                className="flex items-center max-sm:flex-1"
            >
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="me-0.5"
                >
                    <path
                        d="M11.9962 13.0706C13.2886 13.0706 14.3362 12.023 14.3362 10.7306C14.3362 9.43828 13.2886 8.39062 11.9962 8.39062C10.7039 8.39062 9.65625 9.43828 9.65625 10.7306C9.65625 12.023 10.7039 13.0706 11.9962 13.0706Z"
                        stroke="#ED1D1D"
                        strokeWidth="1.125"
                    />
                    <path
                        d="M5.71332 9.3675C7.19082 2.8725 16.8133 2.88 18.2833 9.375C19.1458 13.185 16.7758 16.41 14.6983 18.405C13.1908 19.86 10.8058 19.86 9.29082 18.405C7.22082 16.41 4.85082 13.1775 5.71332 9.3675Z"
                        stroke="#ED1D1D"
                        strokeWidth="1.125"
                    />
                </svg>
                {/* <span className="mr-1 font-light">{region && emirate ? region?.name + " - " + emirate?.name : t("choose-address")}</span> */}
                <div className="mr-1 flex flex-1 font-light">
                    <span className="line-clamp-1 flex-1 font-light">
                        {defaultAddress
                            ? `${defaultAddress?.emirate?.name} - ${defaultAddress?.region?.name} - ${defaultAddress?.name}`
                            : t("choose-address")}
                    </span>
                </div>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7 10L12 15L17 10"
                        stroke="#262626"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
            <div className="flex items-center gap-x-6">
                <div className="max-sm:hidden">
                    <ul className="flex items-center gap-x-3">
                        <li>
                            <a href="https://www.facebook.com/ZabehatyApp?mibextid=LQQJ4d" className="transition hover:opacity-80">
                                <svg
                                    width="21"
                                    height="20"
                                    viewBox="0 0 21 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <ellipse
                                        cx="10.8313"
                                        cy="10"
                                        rx="8.77951"
                                        ry="8.75"
                                        fill="#525252"
                                    />
                                    <path
                                        d="M14.1015 12.676L14.4915 10.2063H12.0518V8.60437C12.0518 7.92856 12.392 7.26942 13.4847 7.26942H14.5946V5.16687C14.5946 5.16687 13.5878 5 12.6257 5C10.6155 5 9.30288 6.18309 9.30288 8.32403V10.2063H7.06934V12.676H9.30288V18.6466C9.75129 18.715 10.21 18.75 10.6774 18.75C11.1447 18.75 11.6034 18.715 12.0518 18.6466V12.676H14.1015Z"
                                        fill="white"
                                    />
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="/" className="transition hover:opacity-80">
                                <svg
                                    width="21"
                                    height="20"
                                    viewBox="0 0 21 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g filter="url(#filter0_i_258_170750)">
                                        <path
                                            d="M10.8652 2C6.44723 2 2.86523 5.582 2.86523 10C2.86523 11.5008 3.2868 12.9 4.00586 14.1003L2.93685 18L6.91992 16.9544C8.0848 17.6167 9.42956 18 10.8652 18C15.2832 18 18.8652 14.418 18.8652 10C18.8652 5.582 15.2832 2 10.8652 2ZM8.12695 6.26823C8.25695 6.26823 8.39053 6.26744 8.50586 6.27344C8.64853 6.27677 8.80381 6.28722 8.95247 6.61589C9.12914 7.00655 9.51382 7.9866 9.56315 8.08594C9.61248 8.18527 9.64744 8.30229 9.57877 8.43229C9.51344 8.56562 9.47946 8.64632 9.38346 8.76432C9.28413 8.87899 9.17529 9.02141 9.08529 9.10807C8.98595 9.20741 8.88338 9.31632 8.99805 9.51432C9.11271 9.71232 9.51094 10.3614 10.0996 10.8854C10.8563 11.5614 11.4947 11.7692 11.6934 11.8685C11.892 11.9678 12.0071 11.9523 12.1217 11.819C12.2397 11.689 12.6173 11.2429 12.7507 11.0443C12.8807 10.8456 13.0134 10.88 13.1934 10.9453C13.376 11.0106 14.3502 11.4905 14.5488 11.5898C14.7475 11.6892 14.8777 11.7383 14.9277 11.819C14.9791 11.9023 14.9791 12.2991 14.8145 12.7617C14.6498 13.2237 13.8412 13.6705 13.4785 13.7018C13.1125 13.7358 12.7709 13.8663 11.0996 13.2083C9.08361 12.4143 7.81222 10.3495 7.71289 10.2161C7.61356 10.0861 6.9056 9.1426 6.9056 8.16927C6.9056 7.1926 7.41767 6.71429 7.597 6.51562C7.77967 6.31696 7.99362 6.26823 8.12695 6.26823Z"
                                            fill="#525252"
                                        />
                                    </g>
                                    <defs>
                                        <filter
                                            id="filter0_i_258_170750"
                                            x="0.865234"
                                            y="0"
                                            width="20"
                                            height="20.534"
                                            filterUnits="userSpaceOnUse"
                                            colorInterpolationFilters="sRGB"
                                        >
                                            <feFlood
                                                floodOpacity="0"
                                                result="BackgroundImageFix"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in="SourceGraphic"
                                                in2="BackgroundImageFix"
                                                result="shape"
                                            />
                                            <feColorMatrix
                                                in="SourceAlpha"
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                result="hardAlpha"
                                            />
                                            <feOffset dy="0.534027" />
                                            <feGaussianBlur stdDeviation="1.0013" />
                                            <feComposite
                                                in2="hardAlpha"
                                                operator="arithmetic"
                                                k2="-1"
                                                k3="1"
                                            />
                                            <feColorMatrix
                                                type="matrix"
                                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                                            />
                                            <feBlend
                                                mode="normal"
                                                in2="shape"
                                                result="effect1_innerShadow_258_170750"
                                            />
                                        </filter>
                                    </defs>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/zabehaty/profilecard/?igsh=NTFydHAzOWRkcnBp" className="transition hover:opacity-80">
                                <svg
                                    width="21"
                                    height="20"
                                    viewBox="0 0 21 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="2.11914"
                                        y="1.25"
                                        width="17.559"
                                        height="17.5"
                                        rx="6"
                                        fill="#525252"
                                    />
                                    <rect
                                        x="2.11914"
                                        y="1.25"
                                        width="17.559"
                                        height="17.5"
                                        rx="6"
                                        fill="#525252"
                                    />
                                    <rect
                                        x="2.11914"
                                        y="1.25"
                                        width="17.559"
                                        height="17.5"
                                        rx="6"
                                        fill="#525252"
                                    />
                                    <path
                                        d="M15.2888 6.5625C15.2888 7.08027 14.8676 7.5 14.3481 7.5C13.8286 7.5 13.4074 7.08027 13.4074 6.5625C13.4074 6.04473 13.8286 5.625 14.3481 5.625C14.8676 5.625 15.2888 6.04473 15.2888 6.5625Z"
                                        fill="white"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M10.899 13.125C12.6307 13.125 14.0345 11.7259 14.0345 10C14.0345 8.27411 12.6307 6.875 10.899 6.875C9.1673 6.875 7.76347 8.27411 7.76347 10C7.76347 11.7259 9.1673 13.125 10.899 13.125ZM10.899 11.875C11.938 11.875 12.7803 11.0355 12.7803 10C12.7803 8.96447 11.938 8.125 10.899 8.125C9.85998 8.125 9.01768 8.96447 9.01768 10C9.01768 11.0355 9.85998 11.875 10.899 11.875Z"
                                        fill="white"
                                    />
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M4.62793 9.75C4.62793 7.6498 4.62793 6.5997 5.03803 5.79754C5.39877 5.09193 5.97438 4.51825 6.68237 4.15873C7.48724 3.75 8.54088 3.75 10.6482 3.75H11.1498C13.2571 3.75 14.3108 3.75 15.1156 4.15873C15.8236 4.51825 16.3992 5.09193 16.76 5.79754C17.1701 6.5997 17.1701 7.6498 17.1701 9.75V10.25C17.1701 12.3502 17.1701 13.4003 16.76 14.2025C16.3992 14.9081 15.8236 15.4817 15.1156 15.8413C14.3108 16.25 13.2571 16.25 11.1498 16.25H10.6482C8.54088 16.25 7.48724 16.25 6.68237 15.8413C5.97438 15.4817 5.39877 14.9081 5.03803 14.2025C4.62793 13.4003 4.62793 12.3502 4.62793 10.25V9.75ZM10.6482 5H11.1498C12.2242 5 12.9545 5.00097 13.519 5.04694C14.0689 5.09171 14.3501 5.17287 14.5462 5.27248C15.0182 5.51217 15.402 5.89462 15.6425 6.36502C15.7424 6.56052 15.8238 6.84078 15.8688 7.3888C15.9149 7.95141 15.9159 8.67928 15.9159 9.75V10.25C15.9159 11.3207 15.9149 12.0486 15.8688 12.6112C15.8238 13.1592 15.7424 13.4395 15.6425 13.635C15.402 14.1054 15.0182 14.4878 14.5462 14.7275C14.3501 14.8271 14.0689 14.9083 13.519 14.9531C12.9545 14.999 12.2242 15 11.1498 15H10.6482C9.57383 15 8.84351 14.999 8.279 14.9531C7.72913 14.9083 7.44793 14.8271 7.25177 14.7275C6.77978 14.4878 6.39604 14.1054 6.15555 13.635C6.0556 13.4395 5.97417 13.1592 5.92924 12.6112C5.88312 12.0486 5.88215 11.3207 5.88215 10.25V9.75C5.88215 8.67928 5.88312 7.95141 5.92924 7.3888C5.97417 6.84078 6.0556 6.56052 6.15555 6.36502C6.39604 5.89462 6.77978 5.51217 7.25177 5.27248C7.44793 5.17287 7.72913 5.09171 8.279 5.04694C8.84351 5.00097 9.57383 5 10.6482 5Z"
                                        fill="white"
                                    />
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="https://x.com/zabehatyapp?s=11" className="transition hover:opacity-80">
                                <svg
                                    width="21"
                                    height="16"
                                    viewBox="0 0 21 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M18.8923 4.02249C18.9044 4.19269 18.9044 4.36288 18.9044 4.53465C18.9044 9.76837 14.7939 15.8045 7.27747 15.8045V15.8013C5.0571 15.8045 2.88286 15.188 1.01367 14.0256C1.33653 14.0633 1.66101 14.0821 1.9863 14.0829C3.82635 14.0845 5.61381 13.486 7.06142 12.3841C5.3128 12.3519 3.77942 11.2468 3.24375 9.63347C3.85629 9.74798 4.48744 9.72445 5.08866 9.56524C3.18225 9.1919 1.81071 7.56837 1.81071 5.68288C1.81071 5.66563 1.81071 5.64916 1.81071 5.63269C2.37874 5.93935 3.01475 6.10955 3.66533 6.12837C1.86977 4.96524 1.3163 2.64994 2.40059 0.839746C4.47531 3.31426 7.5364 4.81857 10.8224 4.97779C10.4931 3.6021 10.943 2.16053 12.0046 1.19347C13.6505 -0.306136 16.239 -0.229273 17.7862 1.36524C18.7013 1.19033 19.5785 0.864844 20.3812 0.403668C20.0761 1.32053 19.4377 2.09935 18.5848 2.59426C19.3948 2.50171 20.1862 2.29151 20.9314 1.97073C20.3828 2.76759 19.6918 3.46171 18.8923 4.02249Z"
                                        fill="#525252"
                                    />
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
                <LanguageSwitcher />
            </div>
        </div>
    );
};
