import React, { useState } from "react";
import {
    GoogleMap,
    useJsApiLoader,
    MarkerF,
    Libraries,
} from "@react-google-maps/api";
import LoadingSpinner from "../loading-spinner";
import Logo from "../../assets/logo";
import { useTranslation } from "react-i18next";
import { cn } from "../../lib/utils";
import axios from "axios";
import useUserStore from "../../store/userStore";
import useModalsStore from "../../store/modalsStore";
import Search from "./search";
import { toast } from "react-toastify";
import { CloseIcon } from "../../assets/icons";
import CustomModal from "../custom-modal";
import {
    invalidate,
    setBranchId,
    setCountry,
    setEmirateId,
    setLat,
    setLng,
    setRegionId,
    setSession,
} from "../../lib/middleware";

const libraries: Libraries = ["places"];

const mapContainerStyle: React.CSSProperties = {
    width: "100%",
    height: "678px",
};

interface MapState {
    map?: google.maps.Map;
    marker: google.maps.LatLngLiteral;
    infoWindowOpen: boolean;
    userLocation?: google.maps.LatLngLiteral;
}

const uaeCenter: google.maps.LatLngLiteral = {
    lat: 24.469072681902656,
    lng: 54.37144234303175,
};

const ksaCenter: google.maps.LatLngLiteral = {
    lat: 24.774265,
    lng: 46.738586,
};

const omCenter: google.maps.LatLngLiteral = {
    lat: 23.614328,
    lng: 58.545284,
};

const zoom = 14;

const MapModal = () => {
    const isMapModalOpen = useModalsStore((state) => state.isMapModalOpen);
    const setMapModalOpen = useModalsStore((state) => state.setMapModalOpen);

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        //googleMapsApiKey: "AIzaSyCMR0GifIRTiL2qcJgMhq1l4xp2ydau7FI",
        googleMapsApiKey: "AIzaSyBCLsDYm5wpEwzYA2jXRr5Rrs-dJFvLFVg",
        libraries: libraries,
    });

    const [map, setMap] = useState<MapState["map"] | null>(null);
    const [mapCenter, setMapCenter] = useState<MapState["marker"]>(uaeCenter);
    const [marker, setMarker] = useState<MapState["marker"]>(uaeCenter);
    const [loading, setLoading] = useState(false);

    const handleMapLoad = (mapInstance: google.maps.Map) => {
        setMap(mapInstance);
    };

    const handleMapClick = (event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
            setMarker({
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            });
        }
    };

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userCoords = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setMarker(userCoords);
                    if (map) {
                        map.panTo(userCoords);
                    }
                },
                (error) => {
                    console.error("Error getting user location:", error);
                },
            );
        }
    };

    const { t } = useTranslation();

    const [step, setStep] = useState(1);
    const [regions, setRegions] = useState<
        {
            id: string;
            name: string;
        }[]
    >([]);

    const [selectedRegionId, setSelectedRegionId] = useState("");
    const [selectedBranchId, setSelectedBranchId] = useState("");
    const [selectedEmirateId, setSelectedEmirateId] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");

    const user = useUserStore((state) => state.user);
    const setLoginModalOpen = useModalsStore(
        (state) => state.setLoginModalOpen,
    );

    async function geolocation() {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append("lat", marker.lat.toString());
            formData.append("lng", marker.lng.toString());
            const response = await axios.post("/geolocation", formData);
            setSelectedEmirateId(response.data.data.emirate.id.toString());
            if (
                response.data.data.available &&
                response.data.data.emirate &&
                response.data.data.region
            ) {
                setSelectedRegionId(response.data.data.region.id.toString());
                setSelectedBranchId(response.data.data.branch.id.toString());
                setRegionId(selectedRegionId);
                setBranchId(selectedBranchId);
                end();
            } else if (
                response.data.data.available &&
                response.data.data.emirate &&
                !response.data.data.region
            ) {
                setRegions(response.data.data.regions);
                setStep(3);
            } else {
                toast.error(t("invalid-country"));
            }
            setLoading(false);
        } catch (err) {
            toast.error(t("invalid-country"));
        }
    }

    async function getBranch(regionId: string) {
        try {
            const formData = new FormData();
            formData.append("emirate_id", selectedEmirateId);
            formData.append("region_id", regionId.toString());
            const response = await axios.post("/branches", formData);
            if (response.data.data) {
                setSelectedBranchId(response.data.data.id.toString());
                setRegionId(regionId.toString());
                setBranchId(response.data.data.id.toString());
                end();
            } else {
                toast.error(t("invalid-country"));
            }
        } catch (err) {
            toast.error(t("something-went-wrong"));
        }
    }

    const end = () => {
        setEmirateId(selectedEmirateId);
        setLat(marker.lat.toString());
        setLng(marker.lng.toString());
        if (!user) {
            setMapModalOpen(false);
            setLoginModalOpen(true);
            setStep(1);
        } else {
            setStep(4);
            // if (
            //     user?.address.filter(
            //         (add) => add.emirate?.id === selectedEmirateId,
            //     ).length
            // )
            //     setStep(4);
            // else {
            //     setStep(1);
            //     setMapModalOpen(false);
            //     invalidate();
            // }
        }
    };

    const setMainAddress = useUserStore((state) => state.setMainAddress);

    return (
        <div
            className={cn("", {
                hidden: !isMapModalOpen,
            })}
        >
            {step === 1 && (
                <div className="-m-5 flex flex-col sm:-m-10">
                    <div className="flex items-center justify-between gap-6 px-6 py-5 sm:gap-10">
                        <Logo className="size-[38px]" />

                        <Search
                            setMapCenter={setMapCenter}
                            setMarker={setMarker}
                        />

                        <button
                            onClick={() => setMapModalOpen(false)}
                            className="ms-auto transition hover:scale-125"
                        >
                            <CloseIcon />
                        </button>
                    </div>
                    {isLoaded ? (
                        <div className="relative">
                            <div className="absolute left-1/2 top-6 z-[1500] flex w-[80%] -translate-x-1/2 flex-wrap gap-2">
                                <button
                                    onClick={() => {
                                        setStep(2);
                                    }}
                                    className="flex-1 rounded-lg bg-[rgba(10,76,38,0.6)] p-4 text-sm text-white transition hover:bg-[rgba(10,76,38,1)]"
                                >
                                    Select Country & Region
                                </button>

                                <button
                                    onClick={getUserLocation}
                                    className="flex items-center justify-center rounded-lg bg-[rgba(10,76,38,0.6)] px-3 transition hover:bg-[rgba(10,76,38,1)]"
                                >
                                    <svg
                                        width="31"
                                        height="31"
                                        viewBox="0 0 31 31"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_258_163183)">
                                            <path
                                                d="M30.0578 14.5955H29.0612C28.8462 11.3074 27.4637 8.24573 25.1122 5.89425C22.7607 3.54277 19.6991 2.16028 16.411 1.94531V0.94873C16.411 0.448508 16.0054 0.0429688 15.5052 0.0429688C15.005 0.0429688 14.5994 0.448508 14.5994 0.94873V1.94531C11.3113 2.16028 8.24957 3.54283 5.89816 5.89425C3.54674 8.24573 2.16424 11.3074 1.94922 14.5955H0.952637C0.452415 14.5955 0.046875 15.0011 0.046875 15.5013C0.046875 16.0015 0.452415 16.4071 0.952637 16.4071H1.94922C2.16424 19.6952 3.54674 22.7569 5.89816 25.1084C8.24963 27.4598 11.3113 28.8423 14.5994 29.0574V30.0539C14.5994 30.5541 15.005 30.9596 15.5052 30.9596C16.0054 30.9596 16.411 30.5541 16.411 30.0539V29.0573C19.6991 28.8423 22.7608 27.4598 25.1123 25.1083C27.4637 22.7568 28.8462 19.6952 29.0612 16.4071H30.0578C30.558 16.4071 30.9635 16.0015 30.9635 15.5013C30.9635 15.0011 30.558 14.5955 30.0578 14.5955ZM16.411 27.2417V26.3101C16.411 25.8098 16.0054 25.4043 15.5052 25.4043C15.005 25.4043 14.5994 25.8098 14.5994 26.3101V27.2417C8.82727 26.8006 4.20595 22.1792 3.76479 16.4071H4.69645C5.19667 16.4071 5.60221 16.0015 5.60221 15.5013C5.60221 15.0011 5.19667 14.5955 4.69645 14.5955H3.76479C4.20595 8.82336 8.82727 4.20205 14.5994 3.76088V4.69255C14.5994 5.19277 15.005 5.59831 15.5052 5.59831C16.0054 5.59831 16.411 5.19277 16.411 4.69255V3.76088C22.1831 4.20205 26.8045 8.82336 27.2456 14.5955H26.314C25.8137 14.5955 25.4082 15.0011 25.4082 15.5013C25.4082 16.0015 25.8137 16.4071 26.314 16.4071H27.2456C26.8045 22.1792 22.1831 26.8006 16.411 27.2417Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M15.5003 7.34766C13.0898 7.34766 10.9213 8.68625 9.84106 10.8411C8.76079 12.996 8.9856 15.5344 10.4277 17.4659L14.7746 23.2876C14.9455 23.5165 15.2146 23.6514 15.5003 23.6514C15.7861 23.6514 16.0551 23.5165 16.2261 23.2876L20.5729 17.4659C22.0151 15.5344 22.2399 12.996 21.1596 10.8412C20.0793 8.68625 17.9108 7.34766 15.5003 7.34766ZM15.5003 16.4053C14.002 16.4053 12.783 15.1863 12.783 13.688C12.783 12.1897 14.002 10.9707 15.5003 10.9707C16.9986 10.9707 18.2176 12.1897 18.2176 13.688C18.2176 15.1863 16.9986 16.4053 15.5003 16.4053Z"
                                                fill="white"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_258_163183">
                                                <rect
                                                    width="30.9167"
                                                    height="30.9167"
                                                    fill="white"
                                                    transform="translate(0.0419922 0.0429688)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </button>
                            </div>
                            <div className="xl:w-[865px]">
                                <GoogleMap
                                    mapContainerStyle={mapContainerStyle}
                                    zoom={zoom}
                                    center={mapCenter}
                                    onLoad={handleMapLoad}
                                    options={{
                                        disableDefaultUI: true,
                                    }}
                                    onClick={handleMapClick}
                                >
                                    <MarkerF
                                        position={marker}
                                        icon="/zabehatyIcons/map-pointer.svg"
                                    ></MarkerF>
                                </GoogleMap>
                            </div>
                        </div>
                    ) : (
                        <LoadingSpinner />
                    )}
                    <div className="flex items-center justify-center px-6 py-4">
                        <button
                            onClick={() => {
                                geolocation();
                            }}
                            className="mx-auto flex w-full max-w-[631px] items-center justify-center rounded-lg bg-primary px-6 py-[18px] text-white transition hover:opacity-80"
                        >
                            {loading ? <LoadingSpinner /> : "Confirm"}
                        </button>
                    </div>
                </div>
            )}
            {step === 2 && (
                <>
                    <div className="mb-4 flex">
                        <button
                            className="transition hover:scale-125 rtl:rotate-180"
                            onClick={() => setStep(1)}
                        >
                            <svg
                                width="40"
                                height="41"
                                viewBox="0 0 40 41"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g filter="url(#filter0_d_258_156948)">
                                    <path
                                        d="M22.5 27.7148L15 19.5898L22.5 11.4648"
                                        stroke="#262626"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </g>
                                <defs>
                                    <filter
                                        id="filter0_d_258_156948"
                                        x="-5"
                                        y="0.214844"
                                        width="50"
                                        height="50"
                                        filterUnits="userSpaceOnUse"
                                        colorInterpolationFilters="sRGB"
                                    >
                                        <feFlood
                                            floodOpacity="0"
                                            result="BackgroundImageFix"
                                        />
                                        <feColorMatrix
                                            in="SourceAlpha"
                                            type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"
                                        />
                                        <feOffset dy="5" />
                                        <feGaussianBlur stdDeviation="2.5" />
                                        <feComposite
                                            in2="hardAlpha"
                                            operator="out"
                                        />
                                        <feColorMatrix
                                            type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
                                        />
                                        <feBlend
                                            mode="normal"
                                            in2="BackgroundImageFix"
                                            result="effect1_dropShadow_258_156948"
                                        />
                                        <feBlend
                                            mode="normal"
                                            in="SourceGraphic"
                                            in2="effect1_dropShadow_258_156948"
                                            result="shape"
                                        />
                                    </filter>
                                </defs>
                            </svg>
                        </button>
                        <button
                            onClick={() => {
                                setStep(1);
                                setMapModalOpen(false);
                            }}
                            className="ms-auto transition hover:scale-125"
                        >
                            <svg
                                width="32"
                                height="33"
                                viewBox="0 0 32 33"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.07975 8.43587C8.70459 7.81103 9.71765 7.81103 10.3425 8.43587L15.9993 14.0927L21.6562 8.43587C22.281 7.81103 23.2941 7.81103 23.9189 8.43587C24.5438 9.06071 24.5438 10.0738 23.9189 10.6986L18.2621 16.3555L23.9189 22.0123C24.5438 22.6372 24.5438 23.6502 23.9189 24.2751C23.2941 24.8999 22.281 24.8999 21.6562 24.2751L15.9993 18.6182L10.3425 24.2751C9.71765 24.8999 8.70459 24.8999 8.07975 24.2751C7.45491 23.6502 7.45491 22.6372 8.07975 22.0123L13.7366 16.3555L8.07975 10.6986C7.45491 10.0738 7.45491 9.06071 8.07975 8.43587Z"
                                    fill="#262626"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="flex flex-col">
                        <span className="mb-8 text-2xl text-foreground">
                            {t("modals.select-country")}
                        </span>
                        <button
                            className={cn(
                                "mb-4 flex items-center gap-2 rounded-xl border-[0.5px] border-[rgba(229,229,229,0.9)] px-4 py-[18px] transition hover:opacity-80",
                                {
                                    "border border-primary shadow-[0px_2px_4px_0px_rgba(74,74,74,0.1)]":
                                        selectedCountry === "uae",
                                },
                            )}
                            onClick={() => {
                                setCountry("uae");
                                setSelectedCountry("uae");
                                setMarker(uaeCenter);
                                setMapCenter(uaeCenter);
                                setStep(1);
                                invalidate();
                            }}
                        >
                            <img
                                src="/zabehatyIcons/uae.png"
                                alt="ksa"
                                className="w-7 object-cover"
                            />
                            <span>{t("countries.uae")}</span>
                        </button>
                        <button
                            className={cn(
                                "mb-4 flex items-center gap-2 rounded-xl border-[0.5px] border-[rgba(229,229,229,0.9)] px-4 py-[18px] transition hover:opacity-80",
                                {
                                    "border border-primary shadow-[0px_2px_4px_0px_rgba(74,74,74,0.1)]":
                                        selectedCountry === "om",
                                },
                            )}
                            onClick={() => {
                                setCountry("om");
                                setSelectedCountry("om");
                                setMarker(omCenter);
                                setMapCenter(omCenter);
                                setStep(1);
                                invalidate();
                            }}
                        >
                            <img
                                src="/zabehatyIcons/om.png"
                                alt="ksa"
                                className="w-7 object-cover"
                            />
                            <span>{t("countries.om")}</span>
                        </button>
                        <button
                            className={cn(
                                "mb-[52px] flex items-center gap-2 rounded-xl border-[0.5px] border-[rgba(229,229,229,0.9)] px-4 py-[18px] transition hover:opacity-80",
                                {
                                    "border border-primary shadow-[0px_2px_4px_0px_rgba(74,74,74,0.1)]":
                                        selectedCountry === "ksa",
                                },
                            )}
                            onClick={() => {
                                setCountry("ksa");
                                setSelectedCountry("ksa");
                                setMarker(ksaCenter);
                                setMapCenter(ksaCenter);
                                setStep(1);
                                invalidate();
                            }}
                        >
                            <img
                                src="/zabehatyIcons/ksa.png"
                                alt="ksa"
                                className="w-7 object-cover"
                            />
                            <span>{t("countries.ksa")}</span>
                        </button>
                    </div>
                </>
            )}
            {step === 3 && (
                <>
                    <div className="mb-4 flex">
                        <button
                            className="transition hover:scale-125 rtl:rotate-180"
                            onClick={() => setStep(1)}
                        >
                            <svg
                                width="40"
                                height="41"
                                viewBox="0 0 40 41"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g filter="url(#filter0_d_258_156948)">
                                    <path
                                        d="M22.5 27.7148L15 19.5898L22.5 11.4648"
                                        stroke="#262626"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </g>
                                <defs>
                                    <filter
                                        id="filter0_d_258_156948"
                                        x="-5"
                                        y="0.214844"
                                        width="50"
                                        height="50"
                                        filterUnits="userSpaceOnUse"
                                        colorInterpolationFilters="sRGB"
                                    >
                                        <feFlood
                                            floodOpacity="0"
                                            result="BackgroundImageFix"
                                        />
                                        <feColorMatrix
                                            in="SourceAlpha"
                                            type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                            result="hardAlpha"
                                        />
                                        <feOffset dy="5" />
                                        <feGaussianBlur stdDeviation="2.5" />
                                        <feComposite
                                            in2="hardAlpha"
                                            operator="out"
                                        />
                                        <feColorMatrix
                                            type="matrix"
                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
                                        />
                                        <feBlend
                                            mode="normal"
                                            in2="BackgroundImageFix"
                                            result="effect1_dropShadow_258_156948"
                                        />
                                        <feBlend
                                            mode="normal"
                                            in="SourceGraphic"
                                            in2="effect1_dropShadow_258_156948"
                                            result="shape"
                                        />
                                    </filter>
                                </defs>
                            </svg>
                        </button>
                        <button
                            onClick={() => {
                                setStep(1);
                                setMapModalOpen(false);
                            }}
                            className="ms-auto transition hover:scale-125"
                        >
                            <svg
                                width="32"
                                height="33"
                                viewBox="0 0 32 33"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.07975 8.43587C8.70459 7.81103 9.71765 7.81103 10.3425 8.43587L15.9993 14.0927L21.6562 8.43587C22.281 7.81103 23.2941 7.81103 23.9189 8.43587C24.5438 9.06071 24.5438 10.0738 23.9189 10.6986L18.2621 16.3555L23.9189 22.0123C24.5438 22.6372 24.5438 23.6502 23.9189 24.2751C23.2941 24.8999 22.281 24.8999 21.6562 24.2751L15.9993 18.6182L10.3425 24.2751C9.71765 24.8999 8.70459 24.8999 8.07975 24.2751C7.45491 23.6502 7.45491 22.6372 8.07975 22.0123L13.7366 16.3555L8.07975 10.6986C7.45491 10.0738 7.45491 9.06071 8.07975 8.43587Z"
                                    fill="#262626"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="flex flex-col">
                        <span className="mb-8 text-2xl text-foreground">
                            {t("modals.select-region")}
                        </span>
                        <div className="scroll flex max-h-[60vh] flex-col gap-y-3 overflow-y-auto pr-[3px]">
                            {regions?.map((region) => (
                                <button
                                    key={region.id}
                                    className={cn(
                                        "mb-4 flex items-center gap-2 rounded-xl border-[0.5px] border-[rgba(229,229,229,0.9)] px-4 py-[18px] capitalize transition hover:opacity-80",
                                        {
                                            "border border-primary shadow-[0px_2px_4px_0px_rgba(74,74,74,0.1)]":
                                                region.id === selectedRegionId,
                                        },
                                    )}
                                    onClick={() => {
                                        getBranch(region.id);
                                        setSelectedRegionId(
                                            region.id.toString(),
                                        );
                                    }}
                                >
                                    <svg
                                        width="18"
                                        height="19"
                                        viewBox="0 0 18 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9.00016 10.5745C10.2925 10.5745 11.3402 9.52688 11.3402 8.23453C11.3402 6.94219 10.2925 5.89453 9.00016 5.89453C7.70781 5.89453 6.66016 6.94219 6.66016 8.23453C6.66016 9.52688 7.70781 10.5745 9.00016 10.5745Z"
                                            stroke="#525252"
                                        />
                                        <path
                                            d="M2.71527 6.8675C4.19277 0.372502 13.8153 0.380002 15.2853 6.875C16.1478 10.685 13.7778 13.91 11.7003 15.905C10.1928 17.36 7.80777 17.36 6.29277 15.905C4.22277 13.91 1.85277 10.6775 2.71527 6.8675Z"
                                            stroke="#525252"
                                        />
                                    </svg>
                                    <span>{region.name}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                </>
            )}

            <CustomModal isOpen={step === 4} setIsOpen={setMapModalOpen}>
                <div className="flex flex-col gap-4">
                    {user?.address
                        // .filter((add) => add.emirate?.id === selectedEmirateId)
                        .map((address) => (
                            <button
                                onClick={() => {
                                    setMapModalOpen(false);
                                    setMainAddress(address);
                                    setSession();
                                }}
                                key={address.id}
                                className="flex gap-2 rounded-lg border border-[rgba(229,229,229,1)] p-4 transition hover:opacity-85"
                            >
                                <svg
                                    width="26"
                                    height="26"
                                    viewBox="0 0 26 26"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.0001 14.5491C14.8668 14.5491 16.3801 13.0358 16.3801 11.1691C16.3801 9.30234 14.8668 7.78906 13.0001 7.78906C11.1334 7.78906 9.62012 9.30234 9.62012 11.1691C9.62012 13.0358 11.1334 14.5491 13.0001 14.5491Z"
                                        stroke="#292D32"
                                        strokeWidth="1.625"
                                    />
                                    <path
                                        d="M3.92168 9.19685C6.05584 -0.184815 19.955 -0.173982 22.0783 9.20769C23.3242 14.711 19.9008 19.3694 16.9 22.251C14.7225 24.3527 11.2775 24.3527 9.08918 22.251C6.09918 19.3694 2.67584 14.7002 3.92168 9.19685Z"
                                        stroke="#292D32"
                                        strokeWidth="1.625"
                                    />
                                </svg>
                                <span className="text-[rgba(82,82,82,1)]">
                                    {address.emirate?.name} -{" "}
                                    {address.region?.name} -{t("street-name")}{" "}
                                    {address.street_name} -{" "}
                                    {t("building-number")}{" "}
                                    {address.building_number}
                                </span>
                            </button>
                        ))}
                </div>
            </CustomModal>
        </div>
    );
};

export default MapModal;
