import React from "react";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type ModalsStore = {
    isLoginModalOpen: boolean;
    isOtpModalOpen: boolean;
    isLanguageCountryModalOpen: boolean;
    isRegisterModalOpen: boolean;
    isForgotPassword: boolean;
    isModalOpen: boolean;
    modalContent: ModalProps;
    isMapModalOpen: boolean;
    isAddingNewAddress: boolean;
    setLoginModalOpen: (isOpen: boolean) => void;
    setOtpModalOpen: (isOpen: boolean) => void;
    setLanguageCountryModalOpen: (isOpen: boolean) => void;
    setRegisterModalOpen: (isOpen: boolean) => void;
    closeModal: () => void;
    setForgotPassword: (isOpen: boolean) => void;
    setModalOpen: (isOpen: boolean) => void;
    setModalContent: ({
        title,
        confirmText,
        cancelText,
        onConfirm,
        onCancel,
        loading,
        icon,
        content,
        variant,
    }: ModalProps) => void;
    setMapModalOpen: (isOpen: boolean) => void;
    setIsAddingNewAddress: (isOpen: boolean) => void;
    resetModalContent: () => void;
};

interface ModalProps {
    title: string | React.ReactNode;
    confirmText: string;
    variant?: string;
    cancelText: string;
    onConfirm: () => void;
    onCancel: () => void;
    loading?: boolean;
    icon: React.ReactNode;
    content?: React.ReactNode;
}

const useModalsStore = create<ModalsStore>()(
    devtools(
        (set) => ({
            isLoginModalOpen: false,
            isOtpModalOpen: false,
            isLanguageCountryModalOpen: false,
            isRegisterModalOpen: false,
            isForgotPassword: false,
            isModalOpen: false,
            modalContent: {
                variant: "",
                title: "",
                confirmText: "",
                cancelText: "",
                onConfirm: () => {},
                onCancel: () => {},
                loading: false,
                icon: null,
            },
            setLoginModalOpen: (isOpen) => set({ isLoginModalOpen: isOpen }),
            setOtpModalOpen: (isOpen) => set({ isOtpModalOpen: isOpen }),
            setRegisterModalOpen: (isOpen) =>
                set({ isRegisterModalOpen: isOpen }),
            setLanguageCountryModalOpen: (isOpen) =>
                set({ isLanguageCountryModalOpen: isOpen }),
            closeModal: () => {
                set({ isLoginModalOpen: false });
                set({ isOtpModalOpen: false });
                set({ isLanguageCountryModalOpen: false });
                set({ isRegisterModalOpen: false });
                set({ isForgotPassword: false });
                set({ isModalOpen: false });
                set({ isMapModalOpen: false });
            },
            setForgotPassword: (isOpen) => set({ isForgotPassword: isOpen }),
            setModalOpen: (isOpen) => set({ isModalOpen: isOpen }),
            setModalContent: ({
                title,
                confirmText,
                icon,
                cancelText,
                onConfirm,
                onCancel,
                loading,
                variant = "",
                content = null,
            }) =>
                set({
                    modalContent: {
                        title,
                        icon,
                        variant,
                        confirmText,
                        cancelText,
                        onConfirm,
                        onCancel,
                        loading,
                        content,
                    },
                }),

            isMapModalOpen: false,
            isAddingNewAddress: false,
            setMapModalOpen: (isOpen) => set({ isMapModalOpen: isOpen }),
            setIsAddingNewAddress: (isOpen) =>
                set({ isAddingNewAddress: isOpen }),
            resetModalContent: () =>
                set({
                    modalContent: {
                        title: "",
                        confirmText: "",
                        cancelText: "",
                        onConfirm: () => {},
                        onCancel: () => {},
                        loading: false,
                        icon: null,
                    },
                }),
        }),
        {
            enabled: true,
            name: "modals-store",
        },
    ),
);

export default useModalsStore;
